import * as React from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { Spin, Button, BackTop } from "antd";

export const GET_USER_DETAILS = gql`
  query getUserDetails {
    user {
      id
      name
      email
      profile_completed
    }
  }
`;

export default function Profile(props) {
  const { loading, error, data: userData } = useQuery(GET_USER_DETAILS);

  if (loading) return <div className="center-div-on-screen"><Spin size="large"/></div>;

  if (error) console.log(error);

  return (
    <div className="p-8 mx-auto">
      <h2 className="text-xl text-center text-teal-700 mb-4">Your profile</h2>

      <div className="space-y-4">
        <div className="flex space-x-1">
          <p className="font-semibold">Contact name: </p>{" "}
          <p>{userData.user[0].name}</p>
        </div>

        <div className="flex space-x-1">
          <p className="font-semibold">Email address: </p>{" "}
          <p>{userData.user[0].email}</p>
        </div>

        <Button
          size="middle"
          type="primary"
          className="bg-teal-500 hover:bg-teal-400 border-none"
          shape="round"
          onClick={() => props.history.push(`/edit-profile`)}
        >
          Edit profile
        </Button>
      </div>
      <BackTop />
    </div>
  );
}
