import React from "react";
import { Form, Formik, ErrorMessage } from "formik";
import { Input, Tabs, Button } from "antd";
import { myFirebase, AuthContext } from "../components/authProvider";
import * as Yup from "yup";
import IHF_logo from "../assets/CIHF_W250px.jpg";
import Qubento_logo from "../assets/QB_W250px.png";
import { showNotification } from "../utils";

const { TabPane } = Tabs;

export default function Login(props) {

  const authState = React.useContext(AuthContext);
  if (authState.status === "authorised") {
    props.history.push("/patrons");
  }

  const pwdRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9]{8,}$/;

  const LoginSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email")
      .ensure()
      .required("Required"),
    password: Yup.string()
      .ensure()
      .min(8, "Password must be atleast 8 characters")
      .matches(pwdRegExp, "Password does not meet criteria")
      .required("Required")
  });

  const handleLogin = (values, { resetForm, setStatus } ) => {
    setStatus("");
    authState.setStatus("authenticating");
    myFirebase
      .auth()
      .signInWithEmailAndPassword(values.email, values.password)
      .then(async res => {
        // console.log("res", res);
        if (res.user.emailVerified === false) {
          myFirebase
          .auth()
          .signOut()
          .then(function() {
            console.log("Successful logout");
            resetForm();
            setStatus("Please verify your email before trying to login");
          })
          .catch(function(error) {
            console.log("error", error);
            resetForm();
            setStatus("Please verify your email before trying to login");
          });
        }
        else {
          authState.setStatus("authenticated");
          setTimeout(() => {
            props.history.push("/patrons");
          }, 2000);
        }
      })
      .catch(function(error) {
        // Handle Errors here.
        console.log(error);
        var errorCode = error.code;
        var errorMessage = error.message;
        if (errorCode === "auth/wrong-password") {
          resetForm();
          setStatus('Wrong password');
        }
        else if (errorCode === 'auth/user-not-found') {
          resetForm();
          setStatus('Email not registered yet. Please sign up first');
        }
        else  {
          resetForm();
          setStatus(errorMessage);
        }
      });
  };

  const handleSignup = (values, { setStatus, resetForm } ) => {
    // console.log(values);
    setStatus("");
    var registerUser = myFirebase.functions().httpsCallable('registerUser');
    registerUser({
      email: values.email,
      password: values.password,
      accountRole: "dataentry"
    }).then(async res => {
        console.log("res", res);
        localStorage.clear();
        await myFirebase
        .auth()
        .signInWithEmailAndPassword(values.email, values.password)
        .then(async res => {
          console.log(res);
          var user = myFirebase.auth().currentUser;
          // await user.getIdToken(true);
          user
          .sendEmailVerification()
          .then(function() {
            // Email sent.
            console.log("email sent");
            showNotification("success", "Success!", "Verification email sent. Please check your email (including spam folder)");
            myFirebase
              .auth()
              .signOut()
              .then(function() {
                  // Sign-out successful.
                  // localStorage.clear();
                  console.log("Successful");
                  resetForm();
              })
              .catch(function(error) {
                  console.log("error", error);
                  resetForm();
                  // An error happened.
              });
          });
        });
      })
      .catch(function(error) {
        console.log(error);
        console.log(error.code);
        console.log(error.message);
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        if (errorCode === "already-exists") {
          resetForm();
          setStatus('Email already registered. Please login instead');
        }
        else  {
          resetForm();
          setStatus(errorMessage);
        }
      });
  };

  return (
    <div className="p-8 mx-auto">
      <Tabs defaultActiveKey="signin">
        <TabPane tab="Signup" key="signup">
          <Formik
            initialValues={{
              email: "",
              password: ""
            }}
            validationSchema={LoginSchema}
            onSubmit={handleSignup}
            enableReinitialize={true}
          >
            {({ values, errors, touched, handleChange, handleBlur, status, isSubmitting }) => (
              <Form>
                <div className="flex flex-col">
                  <label className="text-gray-700 font-semibold">Email address</label>
                  <Input
                    size="large"
                    type="text"
                    name="email"
                    className={
                      errors.email && touched.email ? "border-red-500" : "border-gray-400"
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    
                  />
                  <div
                    style={{
                      minHeight: 21
                    }}
                  >
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                </div>
                <div className="flex flex-col">
                  <label className="text-gray-700 font-semibold">Password</label>
                  <Input
                    size="large"
                    type="password"
                    name="password"
                    className={
                      errors.password && touched.password
                        ? "border-red-500" : "border-gray-400"
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    
                  />
                  <p class="text-gray-800 text-xs italic">Atleast one uppercase, one lowercase and a number. No special characters</p>
                  <div
                    style={{
                      minHeight: 21
                    }}
                  >
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                </div>
                <Button
                  size="middle"
                  type="primary"
                  className="bg-teal-500 hover:bg-teal-400 border-none"
                  shape="round"
                  block={true}
                  htmlType="submit"
                  loading={isSubmitting}
                >
                  Sign up
                </Button>
                <div
                  style={{
                    minHeight: 21
                  }}
                >
                  {!!status && <p className="text-red-500">{status}</p>}
                </div>
              </Form>
            )}
          </Formik>
        </TabPane>

        <TabPane tab="Signin" key="signin">
          <Formik
            initialValues={{
              email: "",
              password: "",
              loginError:""
            }}
            validationSchema={LoginSchema}
            onSubmit={handleLogin}
            enableReinitialize={true}
          >
            {({ values, errors, touched, handleChange, handleBlur, status, isSubmitting }) => (
              <Form>
                <div className="flex flex-col">
                  <label className="text-gray-700 font-semibold">Email address</label>
                  <Input
                    size="large"
                    type="text"
                    name="email"
                    className={
                      errors.email && touched.email ? "border-red-500" : "border-gray-400"
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    
                  />
                  <div
                    style={{
                      minHeight: 21
                    }}
                  >
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                </div>
                <div className="flex flex-col">
                  <label className="text-gray-700 font-semibold">Password</label>
                  <Input
                    size="large"
                    type="password"
                    name="password"
                    className={
                      errors.password && touched.password
                        ? "border-red-500" : "border-gray-400"
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    
                  />
                  <div
                    style={{
                      minHeight: 21
                    }}
                  >
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                </div>
                <Button
                    type="link"
                    onClick={() => props.history.push("/forgot-password")}
                >
                  Forgot password
                </Button>
                <Button
                  size="middle"
                  type="primary"
                  className="bg-teal-500 hover:bg-teal-400 border-none"
                  shape="round"
                  block={true}
                  htmlType="submit"
                  loading={isSubmitting}
                >
                  Signin
                </Button>
                <div
                  style={{
                    minHeight: 21
                  }}
                >
                  {!!status && <p className="text-red-500">{status}</p>}
                </div>
              </Form>
            )}
          </Formik>
        </TabPane>
      </Tabs>
      <div className="flex p-2 justify-center">
        <img
          src={IHF_logo}
          alt="IHF logo"
          className="w-20 md:w-40 h-auto"
        />
        <img
          src={Qubento_logo}
          alt="Qubento logo"
          className="w-20 md:w-40 h-auto"
        /> 
      </div>
    </div>
  );
}
