import * as React from "react";
import "../styles/index.css";
import "antd/dist/antd.css";
import { Tabs } from "antd";
import { AuthContext } from "./authProvider";
import { withRouter } from "react-router-dom";
import { ApolloConsumer } from 'react-apollo';

const { TabPane } = Tabs;

export const TabContainer = withRouter(props => <TabComponent {...props}/>);

class TabComponent extends React.Component {
    handleTabClick = key => {
        this.props.history.push(key);
    };

    render() {
        return (
            <ApolloConsumer>
                {client => (
                    <AuthContext.Consumer>
                        {authState => {
                            return (!!authState.user && !authState.profileIncomplete && (
                                <div className="p-2 bg-green-400">
                                    <Tabs 
                                        activeKey={this.props.location.pathname} 
                                        type="card" 
                                        size="large" 
                                        onChange={this.handleTabClick}
                                        tabBarGutter={5}
                                        tabBarStyle={{margin: "auto"}}
                                    >
                                        <TabPane tab="Patrons" key="/patrons"/>
                                        <TabPane tab="Tests" key="/tests"/>
                                        <TabPane tab="Doctors" key="/doctors" />
                                        <TabPane tab="Organisations" key="/organisations" />
                                        <TabPane tab="Labs" key="/labs" />
                                        <TabPane tab="Kits" key="/kits" />
                                    </Tabs>
                                </div>)
                            );
                        }}
                    </AuthContext.Consumer>
                )}
            </ApolloConsumer>
        );
    }
}