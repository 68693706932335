import * as React from "react";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Input, Radio, Spin, BackTop, Button } from "antd";
import { Form, Formik, ErrorMessage } from "formik";
import { showNotification } from "../utils/index";
import { kitSchema } from "./addKit";

export const GET_KIT = gql`
  query getKit($uuid: uuid!) {
    kit(where: { uuid: { _eq: $uuid } }) {
      test_type
      kit_type        
      kit_name
      manufacturer
    }
  }
`;

export const UPDATE_KIT = gql`
  mutation updateKit($uuid: uuid!, $obj: kit_set_input!) {
    update_kit(where: { uuid: { _eq: $uuid } }, _set: $obj) {
      returning {
        test_type
        kit_type        
        kit_name
        manufacturer
      }
    }
  }
`;

export default function EditKit(props) {
  const { loading, data: queryData, error } = useQuery(GET_KIT, {
    variables: {
      uuid: props.match.params.id
    },
    fetchPolicy: "no-cache"
  });

  const [updateKit] = useMutation(UPDATE_KIT);

  const onSubmit = async values => {
    console.log(values);
    
    await updateKit({
      variables: {
        uuid: props.match.params.id,
        obj: {
          test_type: values.test_type,
          kit_type: values.kit_type,
          kit_name: values.kit_name.trim(),
          manufacturer: values.manufacturer.trim()
        }
      }
    });

    props.history.goBack();
    showNotification("success", "Success!", "Kit updated");
  };

  if (loading) return <div className="center-div-on-screen"><Spin size="large"/></div>;

  if (error) console.log(error);

  return (
    <div className="p-8 mx-auto">
      <h2 className="text-xl text-center text-teal-700 mb-4">Edit kit</h2>
      <Formik
        initialValues={{
          test_type: queryData.kit[0].test_type,
          kit_type: queryData.kit[0].kit_type,
          kit_name: queryData.kit[0].kit_name,
          manufacturer: queryData.kit[0].manufacturer
        }}
        validationSchema={kitSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          isSubmitting
        }) => (
          <Form>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">Test type</label>
              <Radio.Group
                className="flex"
                value={values.test_type}
                onChange={(e) => {
                  setFieldValue("kit_type", "", false);
                  setFieldValue("test_type", e.target.value, true);
                }}
              >
                <Radio
                  className="block m-0 h-auto leading-8 text-gray-700"
                  value={"COVIDAB"}
                >
                  COVIDAB
                </Radio>
                <Radio
                  className="block m-0 h-auto leading-8 text-gray-700"
                  value={"COVIDAG"}
                >
                  COVIDAG
                </Radio>
              </Radio.Group>
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="test_type"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">Kit type</label>
              <Radio.Group
                className="flex"
                value={values.kit_type}
                onChange={(e) => {
                  setFieldValue("kit_type", e.target.value, true);
                }}
              >
                { values.test_type !== "COVIDAB" ?
                <Radio
                  className="block m-0 h-auto leading-8 text-gray-700"
                  value={"PCR"}
                >
                  PCR
                </Radio> : null}
                <Radio
                  className="block m-0 h-auto leading-8 text-gray-700"
                  value={"ELISA"}
                >
                  ELISA
                </Radio>
                <Radio
                  className="block m-0 h-auto leading-8 text-gray-700"
                  value={"LFA"}
                >
                  LFA
                </Radio>
                <Radio
                  className="block m-0 h-auto leading-8 text-gray-700"
                  value={"CLIA"}
                >
                  CLIA
                </Radio>
              </Radio.Group>
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="kit_type"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">Kit name</label>
              <Input
                size="large"
                type="text"
                name="kit_name"
                className={errors.kit_name && touched.kit_name ? "border-red-500" : "border-gray-400"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.kit_name}
              />
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="kit_name"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">Manufacturer</label>
              <Input
                size="large"
                type="text"
                name="manufacturer"
                className={errors.manufacturer && touched.manufacturer ? "border-red-500" : "border-gray-400"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.manufacturer}
              />
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="manufacturer"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <Button
              size="middle"
              type="primary"
              className="bg-teal-500 hover:bg-teal-400 border-none"
              shape="round"
              block={true}
              htmlType="submit"
              loading={isSubmitting}
            >
              Update kit
            </Button>
          </Form>
        )}
      </Formik>
      <BackTop/>
    </div>
  );
}
