import * as React from "react";
import { Form, Formik, ErrorMessage } from "formik";
import { Input, Button } from "antd";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import * as Yup from "yup";
import { showNotification } from "../utils/index";
import { AuthContext } from "../components/authProvider";

export const UPDATE_USER = gql`
  mutation updateUser($obj: user_set_input!) {
    update_user(where: {}, _set: $obj) {
      returning {
        id
        name
      }
    }
  }
`;

export const userDetailsSchema = Yup.object({
  contact_name: Yup.string()
    .ensure()
    .trim("Cannot be empty")
    .min(3, 'Too Short!')
    .max(50, 'Too Long!')
    .required("Required")
});

export default function CompleteProfile(props) {

  const authState = React.useContext(AuthContext);
  
  const [updateUserMutation] = useMutation(UPDATE_USER);

  const onSubmit = async values => {
    console.log(values);

    await updateUserMutation({
      variables: {
        obj: {
          name: values.contact_name.trim(),
          user_type: "DataEntry",
          profile_completed: true
        }
      }
    });

    authState.setProfileIncomplete(false);
    props.history.push("/patrons");
    showNotification("success", "Success!", "Your profile is complete");
  };

  if (!authState.profileIncomplete) props.history.push("/patrons");

  return (
  <div className="p-8 mx-auto">
    <h2 className="text-xl text-center text-teal-700 mb-4">Complete your profile to proceed</h2>
    <Formik
      initialValues={{
        contact_name: ""
      }}
      validationSchema={userDetailsSchema}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        isSubmitting
      }) => (
        <Form>
          <div className="flex flex-col">
            <label className="text-gray-700 font-semibold">Contact name</label>
            <Input
              size="large"
              type="text"
              name="contact_name"
              className={errors.contact_name && touched.contact_name ? "border-red-500" : "border-gray-400"}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.contact_name}
              
            />
            <div
              style={{
                minHeight: 21
              }}
            >
              <ErrorMessage
                name="contact_name"
                component="div"
                className="text-red-500"
              />
            </div>
          </div>
          <Button
            size="middle"
            type="primary"
            className="bg-teal-500 hover:bg-teal-400 border-none"
            shape="round"
            block={true}
            htmlType="submit"
            loading={isSubmitting}
          >
            Complete profile
          </Button>
        </Form>
      )}
    </Formik>
  </div>
  );
}
