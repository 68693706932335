import * as React from "react";
import { Table, Spin, BackTop, Tooltip, Button  } from "antd";
import { EditOutlined } from "@ant-design/icons";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

export const GET_LABS = gql`
  query getLabs {
    lab {
      uuid
      lab_name
      certificate_no
      address
    }
  }
`;

export default function Labs(props) {
  const { data, loading, error } = useQuery(GET_LABS, {
    fetchPolicy: "network-only"
  });
  const [count, setCount] = React.useState(null);

  const columns = [
    {
      title: "ID",
      key: "uuid",
      dataIndex: "uuid"
    },
    {
      title: "Name",
      key: "lab_name",
      dataIndex: "lab_name",
      sorter: (a, b) => a.lab_name.localeCompare(b.lab_name),
      sortDirections: ["ascend","descend"],
      defaultSortOrder: 'ascend'
    },
    {
      title: "Address",
      key: "address",
      render: record => <p>{record.address.line1}{" "}{record.address.line2}{" "}
      {record.address.city}{" "}{record.address.state}{" "}-{" "}
      {record.address.pincode}</p>
    },
    {
      title: "ICMR/NABL certificate number",
      key: "certificate_no",
      dataIndex: "certificate_no"
    },
    {
      title: "",
      key: "edit",
      render: record => {
        return ( 
          <Tooltip title="Edit lab">
            <Button
              type="primary"
              className="bg-gray-600 hover:bg-gray-500 border-none"
              icon={<EditOutlined style={{verticalAlign: "middle"}}/>}
              shape="circle"
              onClick={() => props.history.push(`/edit-lab/${record.uuid}`)}
            />
          </Tooltip>
        );
      }
    }
  ];

  if (loading) return <div className="center-div-on-screen"><Spin size="large"/></div>;

  if (error) console.log(error);

  return (
    <div className="p-8 mx-auto">
      <h2 className="text-xl text-center text-teal-700 mb-4">Labs</h2>
      <div className="my-4 mx-auto overflow-x-auto text-gray-700">
        <Table 
          title={() => <div className="flex flex-row justify-between">
            <p className="font-semibold">Showing {count === null ? data.lab.length : count} Entries</p>
            <Button
              size="middle"
              type="primary"
              className="text-right bg-teal-500 hover:bg-teal-400 border-none"
              shape="round"
              onClick={() => props.history.push("/add-lab")}
            >
              Add lab
            </Button>
          </div>}
          dataSource={data.lab} 
          columns={columns} 
          locale={{emptyText:"No labs yet"}}
          rowKey={(record) => record.uuid}
          onChange={(pagination, filters, sorter, extra) => setCount(extra.currentDataSource.length)}/>
      </div>
      <BackTop />
    </div>
  );
}
