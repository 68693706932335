import * as React from "react";
import { Form, Formik, ErrorMessage } from "formik";
import { Input, DatePicker, Radio, BackTop, Button, Spin, Select } from "antd";
import ImageUpload from "../components/ImageUpload";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { doctorSchema } from "./addDoctor";
import { showNotification } from "../utils/index";
import gql from "graphql-tag";
import moment from "moment";
import { GET_ORGS } from "./orgs";
import { AuthContext } from "../components/authProvider";

const { Option } = Select;

const GET_DOCTOR = gql`
  query getDoctor($uuid: uuid!) {
    doctor(where: { uuid: { _eq: $uuid } }) {
      name
      dob
      gender
      address
      phone
      email
      photo
      imr_no
      specialisation
      qualification
      affiliations {
        organisation {
          uuid
          org_name
          address
        }
      }
    }
  }
`;

export const EDIT_DOCTOR = gql`
  mutation editDoctor($uuid: uuid!, $obj: doctor_set_input!) {
    update_doctor(where: { uuid: { _eq: $uuid } }, _set: $obj) {
      returning {
        name
        dob
        gender
        address
        phone
        email
        photo
        imr_no
        specialisation
        qualification
        affiliations {
          organisation {
            org_name
            address
          }
        }
      }
    }
  }
`;

const EDIT_DOCTOR_AFFILIATIONS = gql`
  mutation editDoctorAffiliations($uuid: uuid!, $obj: [org_doctor_insert_input!]!) {
    delete_org_doctor(where: { doctor_id: { _eq: $uuid } }) {
      affected_rows
    }
    insert_org_doctor(objects: $obj) {
      affected_rows
    }
  }
`;

export default function EditDoctor(props) {
  const authState = React.useContext(AuthContext);
  const { loading, error, data } = useQuery(GET_DOCTOR, {
    variables: {
      uuid: props.match.params.id
    },
    fetchPolicy: "no-cache"
  });
  const { loading: loadingOrgs, error: errorOrgs, data: orgData } = useQuery(GET_ORGS);
  const [editDoctor] = useMutation(EDIT_DOCTOR);
  const [editDoctorAffiliations] = useMutation(EDIT_DOCTOR_AFFILIATIONS);
  const dateFormat = "DD-MM-YYYY";

  const arrayCompare = (a1, a2) => {
    return a1.length === a2.length && a1.every(v => a2.includes(v));
  }

  const onSubmit = async values => {
    console.log(values);

    let old_affiliations = data.doctor[0].affiliations.map(a => a.organisation.uuid);
    let new_affiliations = values.affiliation.map(option => option.key);

    if (!arrayCompare(old_affiliations, new_affiliations)) {
      console.log("updating affiliations");
      let affiliations = [];
      new_affiliations.map(a => affiliations.push({org_id: a, doctor_id: props.match.params.id}));
      // console.log(affiliations);

      await editDoctorAffiliations({
        variables: {
          uuid: props.match.params.id,
          obj: affiliations
        }
      });
    }

    await editDoctor({
      variables: {
        uuid: props.match.params.id,
        obj: {
          name: values.name.trim(),
          specialisation: values.specialisation.trim(),
          qualification: values.qualification.trim(),
          dob: values.dob ? values.dob : null,
          gender: values.gender,
          phone: values.phone ? values.phone : null,
          address: {
            line1: values.line1.trim(),
            line2: values.line2.trim(),
            city: values.city.trim(),
            state: values.state,
            pincode: values.pincode
          },
          email: values.email,
          photo: values.photo,
          imr_no: values.imr_no
        }
      }
    });

    props.history.push("/doctors");
    showNotification("success", "Success!", "Doctor updated");
  };

  if (loading || loadingOrgs) return <div className="center-div-on-screen"><Spin size="large"/></div>;

  if (error) console.log(error);
  if (errorOrgs) console.log(errorOrgs);

  return (
    <div className="p-8 mx-auto">
      <h2 className="text-xl text-center text-teal-700 mb-4">Edit doctor</h2>
      <Formik
        initialValues={{
          name: data.doctor[0].name,
          dob: data.doctor[0].dob ? data.doctor[0].dob : "",
          gender: data.doctor[0].gender,
          phone: data.doctor[0].phone,
          line1: data.doctor[0].address.line1,
          line2: data.doctor[0].address.line2,
          city: data.doctor[0].address.city,
          state: data.doctor[0].address.state,
          pincode: data.doctor[0].address.pincode,
          photo: data.doctor[0].photo,
          imr_no: data.doctor[0].imr_no,
          specialisation: data.doctor[0].specialisation,
          qualification: data.doctor[0].qualification,
          affiliation: data.doctor[0].affiliations.map(a => ({key: a.organisation.uuid, value: a.organisation.org_name}))
        }}
        validationSchema={doctorSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          setFieldTouched,
          setFieldError,
          isSubmitting
        }) => (
          <Form>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">Name</label>
              <Input
                size="large"
                type="text"
                name="name"
                className={errors.name && touched.name ? "border-red-500" : "border-gray-400"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                placeholder="Full name"
              />
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="name"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">Specialisation</label>
              <Input
                size="large"
                type="text"
                name="specialisation"
                className={errors.specialisation && touched.specialisation ? "border-red-500" : "border-gray-400"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.specialisation}
              />
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="specialisation"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">Qualification</label>
              <Input
                size="large"
                type="text"
                name="qualification"
                className={errors.qualification && touched.qualification ? "border-red-500" : "border-gray-400"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.qualification}
              />
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="qualification"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">Date of birth</label>
              <DatePicker
                name="dob"
                format={dateFormat}
                size="large"
                disabledDate={current => {
                  return !current || current.isAfter(moment().endOf("day")) || current.isBefore(moment("1900-01-01").startOf("day"));
                }}
                defaultValue={() => {
                  return moment(values.dob);
                }}
                className={
                  errors.dob && touched.dob ? "w-full border-red-500" : "w-full border-gray-400"
                }
                onChange={
                  (date, dateString) => setFieldValue("dob", moment(date).format("YYYY-MM-DD"))
                }
                onBlur={handleBlur}
                value={values.dob !== "" ? moment(values.dob) : ""}
              />
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="dob"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">Gender</label>
              <Radio.Group
                className="flex"
                value={values.gender}
                onChange={e => {
                  console.log(e);
                  setFieldValue("gender", e.target.value, true);
                }}
              >
                <Radio
                  className="block m-0 h-auto leading-8 text-gray-700"
                  value={"Male"}
                >
                  Male
                </Radio>
                <Radio
                  className="block m-0 h-auto leading-8 text-gray-700"
                  value={"Female"}
                >
                  Female
                </Radio>
                <Radio
                  className="block m-0 h-auto leading-8 text-gray-700"
                  value={"Third"}
                >
                  Third
                </Radio>
              </Radio.Group>
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="gender"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">Phone number</label>
              <Input
                size="large"
                type="text"
                name="phone"
                className={errors.phone && touched.phone ? "border-red-500" : "border-gray-400"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phone}
                prefix={"+91"}
              />
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="phone"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">Email address</label>
              <Input
                size="large"
                type="text"
                name="email"
                className={errors.email && touched.email ? "border-red-500" : "border-gray-400"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">Address</label>
              <Input
                size="large"
                type="text"
                name="line1"
                className={errors.line1 && touched.line1 ? "border-red-500" : "border-gray-400"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.line1}
                placeholder="number, street, locality"
              />
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="line1"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">Address 2</label>
              <Input
                size="large"
                type="text"
                name="line2"
                className={errors.line2 && touched.line2 ? "border-red-500" : "border-gray-400"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.line2}
                placeholder="door number, building name, floor"
              />
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="line2"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">City</label>
              <Input
                size="large"
                type="text"
                name="city"
                className={errors.city && touched.city ? "border-red-500" : "border-gray-400"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.city}
              />
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="city"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">State</label>
              <Select
                size="large"
                name="state"
                placeholder="Select state"
                className={errors.state && touched.state ? "w-full border-red-500" : "w-full border-gray-400"}
                onChange={(value) => setFieldValue("state", value, true)}
                onBlur={handleBlur}
                value={values.state}
                showSearch
                optionFilterProp="value"
                filterOption={(input, option) =>
                  option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value="Andhra Pradesh">Andhra Pradesh</Option>
                <Option value="Andaman and Nicobar Islands">
                  Andaman and Nicobar Islands
                </Option>
                <Option value="Arunachal Pradesh">Arunachal Pradesh</Option>
                <Option value="Assam">Assam</Option>
                <Option value="Bihar">Bihar</Option>
                <Option value="Chandigarh">Chandigarh</Option>
                <Option value="Chhattisgarh">Chhattisgarh</Option>
                <Option value="Dadra and Nagar Haveli and Daman and Diu">
                  Dadra and Nagar Haveli and Daman and Diu
                </Option>
                <Option value="Delhi">Delhi</Option>
                <Option value="Goa">Goa</Option>
                <Option value="Gujarat">Gujarat</Option>
                <Option value="Haryana">Haryana</Option>
                <Option value="Himachal Pradesh">Himachal Pradesh</Option>
                <Option value="Jammu and Kashmir">Jammu and Kashmir</Option>
                <Option value="Jharkhand">Jharkhand</Option>
                <Option value="Karnataka">Karnataka</Option>
                <Option value="Kerala">Kerala</Option>
                <Option value="Ladakh">Ladakh</Option>
                <Option value="Lakshadweep">Lakshadweep</Option>
                <Option value="Madhya Pradesh">Madhya Pradesh</Option>
                <Option value="Maharashtra	">Maharashtra </Option>
                <Option value="Manipur">Manipur</Option>
                <Option value="Meghalaya">Meghalaya</Option>
                <Option value="Mizoram">Mizoram</Option>
                <Option value="Nagaland">Nagaland</Option>
                <Option value="Odisha">Odisha</Option>
                <Option value="Puducherry">Puducherry</Option>
                <Option value="Punjab">Punjab</Option>
                <Option value="Rajasthan">Rajasthan</Option>
                <Option value="Sikkim">Sikkim</Option>
                <Option value="Tamil Nadu">Tamil Nadu</Option>
                <Option value="Telangana">Telangana</Option>
                <Option value="Tripura">Tripura</Option>
                <Option value="Uttar Pradesh">Uttar Pradesh</Option>
                <Option value="Uttarakhand">Uttarakhand</Option>
                <Option value="West Bengal">West Bengal</Option>
              </Select>
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="state"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">Pincode</label>
              <Input
                size="large"
                type="text"
                name="pincode"
                className={
                  errors.pincode && touched.pincode ? "border-red-500" : "border-gray-400"
                }
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.pincode}
              />
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="pincode"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">Photo</label>
              <ImageUpload
                accept="image/*"
                multiple={false}
                name={!!values.photo ? "Image" : "photo"}
                error={touched.photo ? errors.photo : null}
                className={errors.photo && touched.photo ? "border-red-500" : "border-gray-400"}
                value={values.photo}
                headers={{authorization: `Bearer ${authState.token}`}}
                onSuccess={data => {
                  setFieldValue("photo", data[0], true);
                  setFieldTouched("photo", true, true);
                }}
                onError={error => {
                  setFieldTouched("photo", true, false);
                  setFieldError("photo", error);
                }}
              />
              {!!values.photo ? null :
              <p className="text-gray-800 text-xs italic">Upload your photo (max size less than 2 MB)</p>}
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="photo"
                  component="div"
                  className="text-red-500 text-center mb-2"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">IMR number (MCI or state)</label>
              <Input
                size="large"
                type="text"
                name="imr_no"
                className={errors.imr_no && touched.imr_no ? "border-red-500" : "border-gray-400"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.imr_no}
              />
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="imr_no"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">Affiliation(s)</label>
              <Select
                size="large"
                name="affiliation"
                mode="multiple"
                placeholder="Select organisation"
                className={errors.affiliation && touched.affiliation ? "w-full border-red-500" : "w-full border-gray-400"}
                onChange={(value, option) => {if (option.length <= 2) setFieldValue("affiliation", option, true);}}
                onBlur={handleBlur}
                value={values.affiliation.map(option => option.value)}
                showSearch
                optionFilterProp="value"
                filterOption={(input, option) =>
                  option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                tokenSeparators={[',']}
                notFoundContent={"No organisations yet"}
              >
                {orgData.organisation.map(org => <Option key={org.uuid} value={org.org_name}>{org.org_name}, {org.address.city}</Option>)}
              </Select>
              <p className="text-gray-800 text-xs italic">max 2 organisations</p>
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="affiliation"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <Button
              size="middle"
              type="primary"
              className="bg-teal-500 hover:bg-teal-400 border-none"
              shape="round"
              block={true}
              htmlType="submit"
              loading={isSubmitting}
            >
              Update doctor
            </Button>
          </Form>
        )}
      </Formik>
      <BackTop />
    </div>
  );
}
