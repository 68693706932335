import * as React from "react";
import { Form, Formik, ErrorMessage } from "formik";
import { Input, Button } from "antd";
import { myFirebase } from "../components/authProvider";
import * as Yup from "yup";
import { showNotification } from "../utils";

export default function ForgotPassword(props) {
  const EmailValidation = Yup.object({
    email: Yup.string()
      .email("Invalid email")
      .ensure()
      .required("Required")
  });

  const initiatePasswordRecovery = (values, { resetForm, setStatus } ) => {
    console.log(values);
    setStatus("");
    myFirebase
      .auth()
      .sendPasswordResetEmail(values.email.trim())
      .then(async res => {
        console.log("res", res);
        // Email sent.
        console.log("Successfull");
        props.history.push("/");
        showNotification("success", "Success!", "Password reset email sent. Please check your email");
      })
      .catch(function(error) {
        // An error happened.
        console.log("Error", error);
        var errorCode = error.code;
        var errorMessage = error.message;
        if (errorCode === 'auth/user-not-found') {
          resetForm();
          setStatus('Email not registered yet. Please sign up first');
        }
        else  {
          resetForm();
          setStatus(errorMessage);
        }
      });
  };

  return (
    
    <div className="p-8 mx-auto">
      <div
        style={{
          minHeight: 21
        }}
      ></div>
      <Formik
        initialValues={{
          email: ""
        }}
        validationSchema={EmailValidation}
        onSubmit={initiatePasswordRecovery}
        enableReinitialize={true}
      >
        {({ values, errors, touched, handleChange, handleBlur, status, isSubmitting }) => (
          <Form>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">Registered email address</label>
              <Input
                size="large"
                type="text"
                placeholder="Enter your email"
                name="email"
                className={
                  errors.email && touched.email ? "border-red-500" : "border-gray-400"
                }
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                
              />
            </div>
            <div
              style={{
                minHeight: 21
              }}
            >
              <ErrorMessage
                name="email"
                component="div"
                className="text-red-500"
              />
            </div>
            <Button
              size="middle"
              type="primary"
              className="bg-teal-500 hover:bg-teal-400 border-none"
              shape="round"
              block={true}
              htmlType="submit"
              loading={isSubmitting}
            >
              Reset password
            </Button>
            <div
              style={{
                minHeight: 21
              }}
            >
              {!!status && <p className="text-red-500">{status}</p>}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
