import * as React from "react";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Input, BackTop, Select, Spin, Button } from "antd";
import { Form, Formik, ErrorMessage } from "formik";
import { showNotification } from "../utils/index";
import { orgSchema } from "./addOrg";

const { Option } = Select;

export const GET_ORG = gql`
  query getOrg($uuid: uuid!) {
    organisation(where: { uuid: { _eq: $uuid } }) {
      org_name
      tax_id
      address
      phone
      email
    }
  }
`;

export const UPDATE_ORG = gql`
  mutation updateOrg($uuid: uuid!, $obj: organisation_set_input!) {
    update_organisation(where: { uuid: { _eq: $uuid } }, _set: $obj) {
      returning {
        org_name
        tax_id
        address
      }
    }
  }
`;

export default function EditOrg(props) {
  const { loading, data: queryData, error } = useQuery(GET_ORG, {
    variables: {
      uuid: props.match.params.id
    },
    fetchPolicy: "no-cache"
  });

  const [updateOrg] = useMutation(UPDATE_ORG);

  const onSubmit = async values => {
    console.log(values);
    
    await updateOrg({
      variables: {
        uuid: props.match.params.id,
        obj: {
          org_name: values.org_name.trim(),
          tax_id: values.tax_id.trim(),
          address: {
            line1: values.line1.trim(),
            line2: values.line2.trim(),
            city: values.city.trim(),
            state: values.state,
            pincode: values.pincode
          },
          phone: values.phone ? values.phone : null,
          email: values.email
        }
      }
    });

    props.history.goBack();
    showNotification("success", "Success!", "Organisation updated");
  };

  if (loading) return <div className="center-div-on-screen"><Spin size="large"/></div>;

  if (error) console.log(error);

  return (
    <div className="p-8 mx-auto">
      <h2 className="text-xl text-center text-teal-700 mb-4">Edit organisation</h2>
      <Formik
        initialValues={{
          org_name: queryData.organisation[0].org_name,
          tax_id: queryData.organisation[0].tax_id ? queryData.organisation[0].tax_id : "",
          line1: queryData.organisation[0].address.line1,
          line2: queryData.organisation[0].address.line2 ? queryData.organisation[0].address.line2 : "",
          city: queryData.organisation[0].address.city,
          state: queryData.organisation[0].address.state,
          pincode: queryData.organisation[0].address.pincode,
          phone: queryData.organisation[0].phone ? queryData.organisation[0].phone : "",
          email: queryData.organisation[0].email ? queryData.organisation[0].email : "",
        }}
        validationSchema={orgSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          isSubmitting
        }) => (
          <Form>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">Organisation name</label>
              <Input
                size="large"
                type="text"
                name="org_name"
                className={errors.org_name && touched.org_name ? "border-red-500" : "border-gray-400"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.org_name}
                
              />
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="org_name"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">GSTN/PAN</label>
              <Input
                size="large"
                type="text"
                name="tax_id"
                className={errors.tax_id && touched.tax_id ? "border-red-500" : "border-gray-400"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.tax_id}
                
              />
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="tax_id"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">Address</label>
              <Input
                size="large"
                type="text"
                name="line1"
                className={errors.line1 && touched.line1 ? "border-red-500" : "border-gray-400"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.line1}
                placeholder="number, street, locality"
              />
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="line1"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">Address 2</label>
              <Input
                size="large"
                type="text"
                name="line2"
                className={errors.line2 && touched.line2 ? "border-red-500" : "border-gray-400"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.line2}
                placeholder="door number, building name, floor"
              />
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="line2"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">City</label>
              <Input
                size="large"
                type="text"
                name="city"
                className={errors.city && touched.city ? "border-red-500" : "border-gray-400"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.city}
              />
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="city"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">State</label>
              <Select
                name="state"
                size="large"
                placeholder="Select state"
                className={errors.state && touched.state ? "w-full border-red-500" : "w-full border-gray-400"}
                onChange={(value) => setFieldValue("state", value, true)}
                onBlur={handleBlur}
                value={values.state}
                showSearch
                optionFilterProp="value"
                filterOption={(input, option) =>
                  option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value="Andhra Pradesh">Andhra Pradesh</Option>
                <Option value="Andaman and Nicobar Islands">
                  Andaman and Nicobar Islands
                </Option>
                <Option value="Arunachal Pradesh">Arunachal Pradesh</Option>
                <Option value="Assam">Assam</Option>
                <Option value="Bihar">Bihar</Option>
                <Option value="Chandigarh">Chandigarh</Option>
                <Option value="Chhattisgarh">Chhattisgarh</Option>
                <Option value="Dadra and Nagar Haveli and Daman and Diu">
                  Dadra and Nagar Haveli and Daman and Diu
                </Option>
                <Option value="Delhi">Delhi</Option>
                <Option value="Goa">Goa</Option>
                <Option value="Gujarat">Gujarat</Option>
                <Option value="Haryana">Haryana</Option>
                <Option value="Himachal Pradesh">Himachal Pradesh</Option>
                <Option value="Jammu and Kashmir">Jammu and Kashmir</Option>
                <Option value="Jharkhand">Jharkhand</Option>
                <Option value="Karnataka">Karnataka</Option>
                <Option value="Kerala">Kerala</Option>
                <Option value="Ladakh">Ladakh</Option>
                <Option value="Lakshadweep">Lakshadweep</Option>
                <Option value="Madhya Pradesh">Madhya Pradesh</Option>
                <Option value="Maharashtra	">Maharashtra </Option>
                <Option value="Manipur">Manipur</Option>
                <Option value="Meghalaya">Meghalaya</Option>
                <Option value="Mizoram">Mizoram</Option>
                <Option value="Nagaland">Nagaland</Option>
                <Option value="Odisha">Odisha</Option>
                <Option value="Puducherry">Puducherry</Option>
                <Option value="Punjab">Punjab</Option>
                <Option value="Rajasthan">Rajasthan</Option>
                <Option value="Sikkim">Sikkim</Option>
                <Option value="Tamil Nadu">Tamil Nadu</Option>
                <Option value="Telangana">Telangana</Option>
                <Option value="Tripura">Tripura</Option>
                <Option value="Uttar Pradesh">Uttar Pradesh</Option>
                <Option value="Uttarakhand">Uttarakhand</Option>
                <Option value="West Bengal">West Bengal</Option>
              </Select>
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="state"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">Pincode</label>
              <Input
                size="large"
                type="text"
                name="pincode"
                className={
                  errors.pincode && touched.pincode ? "border-red-500" : "border-gray-400"
                }
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.pincode}
              />
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="pincode"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">Phone number</label>
              <Input
                size="large"
                type="text"
                name="phone"
                className={errors.phone && touched.phone ? "border-red-500" : "border-gray-400"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phone}
                prefix={"+91"}
              />
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="phone"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">Email address</label>
              <Input
                size="large"
                type="text"
                name="email"
                className={errors.email && touched.email ? "border-red-500" : "border-gray-400"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <Button
              size="middle"
              type="primary"
              className="bg-teal-500 hover:bg-teal-400 border-none"
              shape="round"
              block={true}
              htmlType="submit"
              loading={isSubmitting}
            >
              Update organisation
            </Button>
          </Form>
        )}
      </Formik>
      <BackTop/>
    </div>
  );
}
