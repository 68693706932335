import * as React from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { Input, BackTop, Button, Select, DatePicker} from "antd";
import { Form, Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { showNotification } from "../utils/index";
import moment from "moment";

const { Option } = Select;

const INSERT_LAB = gql`
  mutation addLab($obj: lab_insert_input!) {
    insert_lab_one(object: $obj) {
      uuid
      lab_name
      certificate_no
      address
    }
  }
`;
const certNoRegExp = /^(\d{1,4})$/;
const phoneRegExp = /^([1-9]{1})(\d{2,10})$/;
const pincodeRegExp = /^([1-9]{1})(\d{2,6})$/;
export const labSchema = Yup.object({
  lab_name: Yup.string()
    .ensure()
    .trim("Cannot be empty")
    .min(3, 'Too Short!')
    .max(50, 'Too Long!')
    .required("Required"),
  certificate_no: Yup.string()
    .ensure()
    .matches(certNoRegExp, "Only numeric, no special characters")
    .length(4, "Number must be exactly 4 digits")
    .required("Required"),
  line1: Yup.string()
    .ensure()
    .trim("Cannot be empty")
    .required("Required"),
  city: Yup.string()
    .ensure()
    .trim("Cannot be empty")
    .min(3, 'Too Short!')
    .max(50, 'Too Long!')
    .required("Required"),
  state: Yup.string()
    .ensure()
    .required("Required"),
  pincode: Yup.string()
    .ensure()
    .matches(pincodeRegExp, "Only numeric, no special characters")
    .length(6, "Pincode must be exactly 6 digits")
    .required("Required"),
  phone: Yup.string()
    //   .ensure()
    //   .required("Required")
      .matches(phoneRegExp, "Only numeric, no special characters")
      .length(10, "Phone number must be exactly 10 digits"),
  email: Yup.string()
      .email("Invalid email")
    //   .ensure()
    //   .required("Required"),
});

export default function AddLab(props) {
  const [insertLab] = useMutation(INSERT_LAB);
  const dateFormat = "DD-MM-YYYY";

  const onSubmit = async values => {
    console.log(values);

    await insertLab({
      variables: {
        obj: {
          lab_name: values.lab_name.trim(),
          certificate_no: "MC-" + values.certificate_no,
          validity: values.validity ? values.validity : null,
          address: {
            line1: values.line1.trim(),
            line2: values.line2.trim(),
            city: values.city.trim(),
            state: values.state,
            pincode: values.pincode
          },
          phone: values.phone ? values.phone : null,
          email: values.email
        }
      }
    });

    props.history.push("/labs");
    showNotification("success", "Success!", "Lab added");
  };

  return (
    <div className="p-8 mx-auto">
      <h2 className="text-xl text-center text-teal-700 mb-4">Add lab</h2>
      <Formik
        initialValues={{
          lab_name: "",
          certificate_no: "",
          validity: "",
          line1: "",
          line2: "",
          city: "",
          state: "",
          pincode: "",
          phone: "",
          email: ""
        }}
        validationSchema={labSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          isSubmitting
        }) => (
          <Form>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">Lab name</label>
              <Input
                size="large"
                type="text"
                name="lab_name"
                className={errors.lab_name && touched.lab_name ? "border-red-500" : "border-gray-400"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.lab_name}
              />
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="lab_name"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">ICMR/NABL certificate number</label>
              <Input
                size="large"
                type="text"
                name="certificate_no"
                className={errors.certificate_no && touched.certificate_no ? "border-red-500" : "border-gray-400"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.certificate_no}
                prefix="MC-"
              />
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="certificate_no"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">Validity</label>
              <DatePicker
                name="validity"
                format={dateFormat}
                disabledDate={current => {
                  return !current || current.isBefore(moment().startOf("day"));
                }}
                size="large"
                className={
                  errors.validity && touched.validity ? "w-full border-red-500" : "w-full border-gray-400"
                }
                onChange={
                  (date, dateString) => setFieldValue("validity", moment(date).format("YYYY-MM-DD"))
                }
                onBlur={handleBlur}
                value={values.validity !== "" ? moment(values.validity) : ""}
                
              />
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="validity"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">Address</label>
              <Input
                size="large"
                type="text"
                name="line1"
                className={errors.line1 && touched.line1 ? "border-red-500" : "border-gray-400"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.line1}
                placeholder="number, street, locality"
              />
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="line1"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">Address 2</label>
              <Input
                size="large"
                type="text"
                name="line2"
                className={errors.line2 && touched.line2 ? "border-red-500" : "border-gray-400"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.line2}
                placeholder="door number, building name, floor"
              />
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="line2"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">City</label>
              <Input
                size="large"
                type="text"
                name="city"
                className={errors.city && touched.city ? "border-red-500" : "border-gray-400"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.city}
              />
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="city"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">State</label>
              <Select
                name="state"
                size="large"
                placeholder="Select state"
                className={errors.state && touched.state ? "w-full border-red-500" : "w-full border-gray-400"}
                onChange={(value) => setFieldValue("state", value, true)}
                onBlur={handleBlur}
                value={values.state}
                showSearch
                optionFilterProp="value"
                filterOption={(input, option) =>
                  option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value="Andhra Pradesh">Andhra Pradesh</Option>
                <Option value="Andaman and Nicobar Islands">
                  Andaman and Nicobar Islands
                </Option>
                <Option value="Arunachal Pradesh">Arunachal Pradesh</Option>
                <Option value="Assam">Assam</Option>
                <Option value="Bihar">Bihar</Option>
                <Option value="Chandigarh">Chandigarh</Option>
                <Option value="Chhattisgarh">Chhattisgarh</Option>
                <Option value="Dadra and Nagar Haveli and Daman and Diu">
                  Dadra and Nagar Haveli and Daman and Diu
                </Option>
                <Option value="Delhi">Delhi</Option>
                <Option value="Goa">Goa</Option>
                <Option value="Gujarat">Gujarat</Option>
                <Option value="Haryana">Haryana</Option>
                <Option value="Himachal Pradesh">Himachal Pradesh</Option>
                <Option value="Jammu and Kashmir">Jammu and Kashmir</Option>
                <Option value="Jharkhand">Jharkhand</Option>
                <Option value="Karnataka">Karnataka</Option>
                <Option value="Kerala">Kerala</Option>
                <Option value="Ladakh">Ladakh</Option>
                <Option value="Lakshadweep">Lakshadweep</Option>
                <Option value="Madhya Pradesh">Madhya Pradesh</Option>
                <Option value="Maharashtra	">Maharashtra </Option>
                <Option value="Manipur">Manipur</Option>
                <Option value="Meghalaya">Meghalaya</Option>
                <Option value="Mizoram">Mizoram</Option>
                <Option value="Nagaland">Nagaland</Option>
                <Option value="Odisha">Odisha</Option>
                <Option value="Puducherry">Puducherry</Option>
                <Option value="Punjab">Punjab</Option>
                <Option value="Rajasthan">Rajasthan</Option>
                <Option value="Sikkim">Sikkim</Option>
                <Option value="Tamil Nadu">Tamil Nadu</Option>
                <Option value="Telangana">Telangana</Option>
                <Option value="Tripura">Tripura</Option>
                <Option value="Uttar Pradesh">Uttar Pradesh</Option>
                <Option value="Uttarakhand">Uttarakhand</Option>
                <Option value="West Bengal">West Bengal</Option>
              </Select>
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="state"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">Pincode</label>
              <Input
                size="large"
                type="text"
                name="pincode"
                className={
                  errors.pincode && touched.pincode ? "border-red-500" : "border-gray-400"
                }
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.pincode}
              />
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="pincode"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">Phone number</label>
              <Input
                size="large"
                type="text"
                name="phone"
                className={errors.phone && touched.phone ? "border-red-500" : "border-gray-400"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phone}
                prefix={"+91"}
              />
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="phone"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">Email address</label>
              <Input
                size="large"
                type="text"
                name="email"
                className={errors.email && touched.email ? "border-red-500" : "border-gray-400"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <Button
              size="middle"
              type="primary"
              className="bg-teal-500 hover:bg-teal-400 border-none"
              shape="round"
              block={true}
              htmlType="submit"
              loading={isSubmitting}
            >
              Add lab
            </Button>
          </Form>
        )}
      </Formik>
      <BackTop/>
    </div>
  );
}
