import * as React from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { Input, BackTop, Button, Select} from "antd";
import { Form, Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { showNotification } from "../utils/index";

const { Option } = Select;

const INSERT_ORG = gql`
  mutation addOrg($obj: organisation_insert_input!) {
    insert_organisation_one(object: $obj) {
      uuid
      org_name
      tax_id
      address
    }
  }
`;

const phoneRegExp = /^([1-9]{1})(\d{2,10})$/;
const pincodeRegExp = /^([1-9]{1})(\d{2,6})$/;
export const orgSchema = Yup.object({
  org_name: Yup.string()
    .ensure()
    .trim("Cannot be empty")
    .min(3, 'Too Short!')
    .max(50, 'Too Long!')
    .required("Required"),
  tax_id: Yup.string()
    .ensure()
    .min(10, 'Too Short!')
    .max(15, 'Too Long!')
    .trim("Cannot be empty")
    .required("Required"),
  line1: Yup.string()
    .ensure()
    .trim("Cannot be empty")
    .required("Required"),
  city: Yup.string()
    .ensure()
    .trim("Cannot be empty")
    .min(3, 'Too Short!')
    .max(50, 'Too Long!')
    .required("Required"),
  state: Yup.string()
    .ensure()
    .required("Required"),
  pincode: Yup.string()
    .ensure()
    .matches(pincodeRegExp, "Only numeric, no special characters")
    .length(6, "Pincode must be exactly 6 digits")
    .required("Required"),
  phone: Yup.string()
  //   .ensure()
  //   .required("Required")
    .matches(phoneRegExp, "Only numeric, no special characters")
    .length(10, "Phone number must be exactly 10 digits"),
  email: Yup.string()
    .email("Invalid email")
  //   .ensure()
  //   .required("Required"),
});

export default function AddOrg(props) {
  const [insertOrg] = useMutation(INSERT_ORG);

  const onSubmit = async values => {
    console.log(values);

    await insertOrg({
      variables: {
        obj: {
          org_name: values.org_name.trim(),
          tax_id: values.tax_id.trim(),
          address: {
            line1: values.line1.trim(),
            line2: values.line2.trim(),
            city: values.city.trim(),
            state: values.state,
            pincode: values.pincode
          },
          phone: values.phone ? values.phone : null,
          email: values.email
        }
      }
    });

    props.history.push("/organisations");
    showNotification("success", "Success!", "Organisation added");
  };

  return (
    <div className="p-8 mx-auto">
      <h2 className="text-xl text-center text-teal-700 mb-4">Add organisation</h2>
      <Formik
        initialValues={{
          org_name: "",
          line1: "",
          line2: "",
          city: "",
          state: "",
          pincode: "",
          phone: "",
          email: "",
          tax_id: ""
        }}
        validationSchema={orgSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          isSubmitting
        }) => (
          <Form>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">Organisation name</label>
              <Input
                size="large"
                type="text"
                name="org_name"
                className={errors.org_name && touched.org_name ? "border-red-500" : "border-gray-400"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.org_name}
                
              />
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="org_name"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">GSTN/PAN</label>
              <Input
                size="large"
                type="text"
                name="tax_id"
                className={errors.tax_id && touched.tax_id ? "border-red-500" : "border-gray-400"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.tax_id}
                
              />
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="tax_id"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">Address</label>
              <Input
                size="large"
                type="text"
                name="line1"
                className={errors.line1 && touched.line1 ? "border-red-500" : "border-gray-400"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.line1}
                placeholder="number, street, locality"
              />
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="line1"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">Address 2</label>
              <Input
                size="large"
                type="text"
                name="line2"
                className={errors.line2 && touched.line2 ? "border-red-500" : "border-gray-400"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.line2}
                placeholder="door number, building name, floor"
              />
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="line2"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">City</label>
              <Input
                size="large"
                type="text"
                name="city"
                className={errors.city && touched.city ? "border-red-500" : "border-gray-400"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.city}
              />
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="city"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">State</label>
              <Select
                name="state"
                size="large"
                placeholder="Select state"
                className={errors.state && touched.state ? "w-full border-red-500" : "w-full border-gray-400"}
                onChange={(value) => setFieldValue("state", value, true)}
                onBlur={handleBlur}
                value={values.state}
                showSearch
                optionFilterProp="value"
                filterOption={(input, option) =>
                  option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value="Andhra Pradesh">Andhra Pradesh</Option>
                <Option value="Andaman and Nicobar Islands">
                  Andaman and Nicobar Islands
                </Option>
                <Option value="Arunachal Pradesh">Arunachal Pradesh</Option>
                <Option value="Assam">Assam</Option>
                <Option value="Bihar">Bihar</Option>
                <Option value="Chandigarh">Chandigarh</Option>
                <Option value="Chhattisgarh">Chhattisgarh</Option>
                <Option value="Dadra and Nagar Haveli and Daman and Diu">
                  Dadra and Nagar Haveli and Daman and Diu
                </Option>
                <Option value="Delhi">Delhi</Option>
                <Option value="Goa">Goa</Option>
                <Option value="Gujarat">Gujarat</Option>
                <Option value="Haryana">Haryana</Option>
                <Option value="Himachal Pradesh">Himachal Pradesh</Option>
                <Option value="Jammu and Kashmir">Jammu and Kashmir</Option>
                <Option value="Jharkhand">Jharkhand</Option>
                <Option value="Karnataka">Karnataka</Option>
                <Option value="Kerala">Kerala</Option>
                <Option value="Ladakh">Ladakh</Option>
                <Option value="Lakshadweep">Lakshadweep</Option>
                <Option value="Madhya Pradesh">Madhya Pradesh</Option>
                <Option value="Maharashtra	">Maharashtra </Option>
                <Option value="Manipur">Manipur</Option>
                <Option value="Meghalaya">Meghalaya</Option>
                <Option value="Mizoram">Mizoram</Option>
                <Option value="Nagaland">Nagaland</Option>
                <Option value="Odisha">Odisha</Option>
                <Option value="Puducherry">Puducherry</Option>
                <Option value="Punjab">Punjab</Option>
                <Option value="Rajasthan">Rajasthan</Option>
                <Option value="Sikkim">Sikkim</Option>
                <Option value="Tamil Nadu">Tamil Nadu</Option>
                <Option value="Telangana">Telangana</Option>
                <Option value="Tripura">Tripura</Option>
                <Option value="Uttar Pradesh">Uttar Pradesh</Option>
                <Option value="Uttarakhand">Uttarakhand</Option>
                <Option value="West Bengal">West Bengal</Option>
              </Select>
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="state"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">Pincode</label>
              <Input
                size="large"
                type="text"
                name="pincode"
                className={
                  errors.pincode && touched.pincode ? "border-red-500" : "border-gray-400"
                }
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.pincode}
              />
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="pincode"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">Phone number</label>
              <Input
                size="large"
                type="text"
                name="phone"
                className={errors.phone && touched.phone ? "border-red-500" : "border-gray-400"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phone}
                prefix={"+91"}
              />
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="phone"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">Email address</label>
              <Input
                size="large"
                type="text"
                name="email"
                className={errors.email && touched.email ? "border-red-500" : "border-gray-400"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <Button
              size="middle"
              type="primary"
              className="bg-teal-500 hover:bg-teal-400 border-none"
              shape="round"
              block={true}
              htmlType="submit"
              loading={isSubmitting}
            >
              Add organisation
            </Button>
          </Form>
        )}
      </Formik>
      <BackTop/>
    </div>
  );
}
