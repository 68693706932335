import * as React from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { Input, Radio, BackTop, Button} from "antd";
import { Form, Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { showNotification } from "../utils/index";

const INSERT_KIT = gql`
  mutation addKit($obj: kit_insert_input!) {
    insert_kit_one(object: $obj) {
      uuid
      test_type
      kit_type
      kit_name
      manufacturer
    }
  }
`;

export const kitSchema = Yup.object({
  test_type: Yup.string()
    .ensure()
    .required("Required"),
  kit_type: Yup.string()
    .ensure()
    .required("Required"),
  kit_name: Yup.string()
    .ensure()
    .trim("Cannot be empty")
    .min(3, 'Too Short!')
    .max(50, 'Too Long!')
    .required("Required"),
  manufacturer: Yup.string()
    .ensure()
    .trim("Cannot be empty")
    .min(3, 'Too Short!')
    .max(50, 'Too Long!')
    .required("Required")
});

export default function AddKit(props) {
  const [insertKit] = useMutation(INSERT_KIT);

  const onSubmit = async values => {
    console.log(values);

    await insertKit({
      variables: {
        obj: {
          test_type: values.test_type,
          kit_type: values.kit_type,
          kit_name: values.kit_name.trim(),
          manufacturer: values.manufacturer.trim()
        }
      }
    });

    props.history.push("/kits");
    showNotification("success", "Success!", "Kit added");
  };

  return (
    <div className="p-8 mx-auto">
      <h2 className="text-xl text-center text-teal-700 mb-4">Add kit</h2>
      <Formik
        initialValues={{
          test_type: "",
          kit_type: "",
          kit_name: "",
          manufacturer: ""
        }}
        validationSchema={kitSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          isSubmitting
        }) => (
          <Form>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">Test type</label>
              <Radio.Group
                className="flex"
                value={values.test_type}
                onChange={(e) => {
                  setFieldValue("kit_type", "", false);
                  setFieldValue("test_type", e.target.value, true);
                }}
              >
                <Radio
                  className="block m-0 h-auto leading-8 text-gray-700"
                  value={"COVIDAB"}
                >
                  COVIDAB
                </Radio>
                <Radio
                  className="block m-0 h-auto leading-8 text-gray-700"
                  value={"COVIDAG"}
                >
                  COVIDAG
                </Radio>
              </Radio.Group>
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="test_type"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">Kit type</label>
              <Radio.Group
                className="flex"
                value={values.kit_type}
                onChange={(e) => {
                  setFieldValue("kit_type", e.target.value, true);
                }}
              >
                { values.test_type !== "COVIDAB" ?
                <Radio
                  className="block m-0 h-auto leading-8 text-gray-700"
                  value={"PCR"}
                >
                  PCR
                </Radio> : null}
                <Radio
                  className="block m-0 h-auto leading-8 text-gray-700"
                  value={"ELISA"}
                >
                  ELISA
                </Radio>
                <Radio
                  className="block m-0 h-auto leading-8 text-gray-700"
                  value={"LFA"}
                >
                  LFA
                </Radio>
                <Radio
                  className="block m-0 h-auto leading-8 text-gray-700"
                  value={"CLIA"}
                >
                  CLIA
                </Radio>
              </Radio.Group>
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="kit_type"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">Kit name</label>
              <Input
                size="large"
                type="text"
                name="kit_name"
                className={errors.kit_name && touched.kit_name ? "border-red-500" : "border-gray-400"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.kit_name}
              />
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="kit_name"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">Manufacturer</label>
              <Input
                size="large"
                type="text"
                name="manufacturer"
                className={errors.manufacturer && touched.manufacturer ? "border-red-500" : "border-gray-400"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.manufacturer}
              />
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="manufacturer"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <Button
              size="middle"
              type="primary"
              className="bg-teal-500 hover:bg-teal-400 border-none"
              shape="round"
              block={true}
              htmlType="submit"
              loading={isSubmitting}
            >
              Add kit
            </Button>
          </Form>
        )}
      </Formik>
      <BackTop/>
    </div>
  );
}
