import * as React from "react";
import { Table, Spin, BackTop, Tooltip, Button } from "antd";
import { EditOutlined } from "@ant-design/icons";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

export const GET_DOCTORS = gql`
  query getDoctors {
    doctor {
      uuid
      name
      specialisation
      address
      imr_no
      affiliations {
        organisation {
          org_name
          address
        }
      }
    }
  }
`;

export default function Doctors(props) {
  const { data, loading, error } = useQuery(GET_DOCTORS, {
    fetchPolicy: "network-only"
  });
  const [count, setCount] = React.useState(null);

  const columns = [
    {
      title: "ID",
      key: "uuid",
      dataIndex: "uuid"
    },
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["ascend","descend"],
      defaultSortOrder: 'ascend'
    },
    {
      title: "Specialisation",
      key: "specialisation",
      dataIndex: "specialisation",
      sorter: (a, b) => a.specialisation.localeCompare(b.specialisation),
      sortDirections: ["ascend","descend"]
    },
    {
      title: "City",
      key: "city",
      render: record => <p>{record.address.city}</p>,
      sorter: (a, b) => a.address.city.localeCompare(b.address.city),
      sortDirections: ["ascend","descend"]
    },
    {
      title: "IMR number",
      key: "imr_no",
      dataIndex: "imr_no"
    },
    {
      title: "Affiliations",
      key: "affiliations",
      render: record => record.affiliations.length ? record.affiliations.map(a => <p>{a.organisation.org_name}, {a.organisation.address.city}</p>) : <p>None</p>
    },
    {
      title: "",
      key: "edit",
      render: record => {
        return ( 
          <Tooltip title="Edit doctor">
            <Button
              type="primary"
              className="bg-gray-600 hover:bg-gray-500 border-none"
              icon={<EditOutlined style={{verticalAlign: "middle"}}/>}
              shape="circle"
              onClick={() => props.history.push(`/edit-doctor/${record.uuid}`)}
            />
          </Tooltip>
        );
      }
    }
  ];

  if (loading) return <div className="center-div-on-screen"><Spin size="large"/></div>;

  if (error) console.log(error);

  return (
    <div className="p-8 mx-auto">
      <h2 className="text-xl text-center text-teal-700 mb-4">Doctors</h2>
      <div className="my-4 mx-auto overflow-x-auto text-gray-700">
        <Table 
          title={() => <div className="flex flex-row justify-between">
            <p className="text-left font-semibold">Showing {count === null ? data.doctor.length : count} Entries</p>
            <Button
              size="middle"
              type="primary"
              className="text-right bg-teal-500 hover:bg-teal-400 border-none"
              shape="round"
              onClick={() => props.history.push("/add-doctor")}
            >
              Add doctor
            </Button>
          </div>}
          dataSource={data.doctor} 
          columns={columns} 
          locale={{emptyText:"No doctors yet"}}
          rowKey={(record) => record.uuid}
          onChange={(pagination, filters, sorter, extra) => setCount(extra.currentDataSource.length)}/>
      </div>
      <BackTop />
    </div>
  );
}
