import * as React from "react";
import { Table, Spin, BackTop, Tooltip, Button, Input, Space } from "antd";
import { EyeOutlined, FileTextOutlined, EditOutlined, SearchOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import moment from "moment";
import { getDownloadSignedUrl } from "../utils/s3_helper";
import { AuthContext } from "../components/authProvider";

export const GET_SUBJECTS = gql`
  query getSubjects {
    subject {
      uuid
      created_at
      name
      gender
      dob
      gov_id
      phone
      affiliations {
        organisation {
          org_name
          address
        }
      }
      labs {
        test_results {
          uuid
          created_at
          test_date
          kit {
            kit_name
            manufacturer
            test_type
            kit_type
          }
          kit_no
          result
          remarks
          image
          lab_report
          status
          lab_subject {
            lab {
              lab_name
              address
            }
          }
          doctor {
            name
          }
        }
      }
    }
  }
`;

export default function Subjects(props) {
  const authState = React.useContext(AuthContext);
  const { data, loading, error } = useQuery(GET_SUBJECTS, {fetchPolicy: "network-only"});

  const [count, setCount] = React.useState(null);

  const downloadUrl = async (event, publicUrl) => {
    event.preventDefault();
    const url = await getDownloadSignedUrl(publicUrl, {headers: {authorization: `Bearer ${authState.token}`}});
    let downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.target = "_blank";
    downloadLink.download = "doc";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const testResultsTable = (labs) => {
    let test_results=[];
    labs.map(lab => lab.test_results.map(t => test_results.push(t)));
    console.log(test_results);
    const testResultsColumns = [
      {
        title: "Test date",
        key: "test_date",
        render: record => {
          return <p>{moment(record.test_date).format("DD-MM-YYYY")}</p>;
        },
        sorter: (a, b) => moment(a.test_date).diff(moment(b.test_date)),
        sortDirections: ["ascend","descend"],
        defaultSortOrder: 'descend'
      },
      {
        title: "Lab",
        key: "lab",
        render: record => <p className="capitalize">{record.lab_subject.lab.lab_name}, {record.lab_subject.lab.address.city}</p>,
        sorter: (a, b) => a.lab_subject.lab.lab_name.localeCompare(b.lab_subject.lab.lab_name),
        sortDirections: ["ascend","descend"],
      },
      {
        title: "Referred by",
        key: "doctor",
        render: record => record.doctor ? <p className="capitalize">Dr. {record.doctor.name}</p> : <p>Not available</p>,
        sorter: (a, b) => a.doctor.name.localeCompare(b.doctor.name),
        sortDirections: ["ascend","descend"],
      },
      {
        title: "Test type",
        key: "test_type",
        filters: [
          { text: "COVID-19 Antibody", value: "COVIDAB" },
          { text: "COVID-19 Antigen", value: "COVIDAG" }
        ],
        onFilter: (value, record) => record.kit.test_type.includes(value),
        filterMultiple: false,
        render: record => {
          return <p>{record.kit.test_type === "COVIDAB" ? 
                      "COVID-19 Antibody" : 
                      "COVID-19 Antigen"}</p>;
        }
      },
      {
        title: "Kit",
        key: "kit",
        render: record => {
          return(
            <div>
              <p>Kit name: {record.kit.kit_name}</p>
              <p>Manufacturer: {record.kit.manufacturer}</p>
              <p>Kit type: {record.kit.kit_type}</p>
              <p>Kit/Sample ID: {record.kit_no}</p>
            </div>
          );
        },
        sorter: (a, b) => a.kit.kit_name.localeCompare(b.kit.kit_name),
        sortDirections: ["ascend","descend"]
      },
      {
        title: "Result",
        key: "result",
        filters: [
          { text: "Positive", value: true },
          { text: "Negative", value: false }
        ],
        onFilter: (value, record) => record.result === value,
        render: record => {
          return <p>{record.result ? "Positive" : "Negative"}</p>;
        }
      },
      {
        title: "Result image",
        key: "image",
        render: record => {
          return (record.image ?
            <Tooltip title="View">
                <Button
                  icon={<EyeOutlined style={{verticalAlign: "middle"}}/>}
                  shape="circle"
                  type="primary"
                  className="bg-gray-600 hover:bg-gray-500 border-none"
                  onClick={(event) => downloadUrl(event, record.image)}
                />
            </Tooltip> : <p>Not Available</p>
          );
        }
      },
      {
        title: "Lab report",
        key: "lab_report",
        render: record => {
          return (record.lab_report.report ?
            <Tooltip title="Open document">
                <Button
                  icon={<FileTextOutlined style={{verticalAlign: "middle"}}/>}
                  shape="circle"
                  type="primary"
                  className="bg-gray-600 hover:bg-gray-500 border-none"
                  onClick={(event) => downloadUrl(event, record.lab_report.report)}
                />
            </Tooltip> : <p>Not Available</p>
          );
        }
      },
      {
        title: "",
        key: "edit",
        render: record => {
          return (
            record.status === "New" && 
            <Tooltip title="Edit observation">
              <Button
                type="primary"
                className="bg-gray-600 hover:bg-gray-500 border-none"
                icon={<EditOutlined style={{verticalAlign: "middle"}}/>}
                shape="circle"
                onClick={() => props.history.push(`/edit-observation/${record.uuid}`)}
              />
            </Tooltip>
          );
        }
      }
    ];
    return (
    <Table
      title={() => <p className="text-l font-semibold">Test observations</p>}
      dataSource={test_results}
      columns={testResultsColumns}
      pagination={false}
      rowKey={(record) => record.uuid}
      expandable={{
        expandedRowRender: record => <p> Remarks: {record.remarks} </p>,
        rowExpandable: record => record.remarks,
      }}
      locale={{emptyText:"No observations yet"}}/>);
  };

  const columns = [
    {
      title: "Registration date",
      key: "created_at",
      render: record => <p>{moment(record.created_at).format("DD-MM-YYYY")}</p>,
      sorter: (a, b) => moment(a.created_at).diff(moment(b.created_at)),
      sortDirections: ["ascend","descend"],
      defaultSortOrder: 'descend'
    },
    {
      title: "Registration ID",
      key: "uuid",
      render: record => <p>{record.uuid}</p>,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div className="p-2">
          <Input
            placeholder={"Search ID"}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={confirm}
            className="w-48 my-2 block"
          />
          <Space>
            <Button
              type="primary"
              onClick={confirm}
              icon={<SearchOutlined />}
              size="small"
            >
              Search
            </Button>
            <Button 
              onClick={clearFilters} 
              size="small"
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined className={filtered ? "text-blue-500" : ""} />,
      onFilter: (value, record) =>
        record.uuid
          ? record.uuid.toString().toLowerCase().includes(value.toLowerCase())
          : ''
    },
    {
      title: "Name",
      key: "name",
      render: record => <p className="capitalize">{record.name}</p>,
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["ascend","descend"],
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div className="p-2">
          <Input
            placeholder={"Search name"}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={confirm}
            className="w-48 my-2 block"
          />
          <Space>
            <Button
              type="primary"
              onClick={confirm}
              icon={<SearchOutlined />}
              size="small"
            >
              Search
            </Button>
            <Button 
              onClick={clearFilters} 
              size="small"
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined className={filtered ? "text-blue-500" : ""} />,
      onFilter: (value, record) =>
        record.name
          ? record.name.toString().toLowerCase().includes(value.toLowerCase())
          : ''  
    },
    {
      title: "Gender",
      key: "gender",
      render: record => <p className="capitalize">{record.gender}</p>,
      filters: [
        { text: "Male", value: "Male" },
        { text: "Female", value: "Female" }
      ],
      onFilter: (value, record) => record.gender === value
    },
    {
      title: "Age",
      key: "age",
      render: record => {
        return moment().diff(moment(record.dob), "years") ? 
          <p>{moment().diff(moment(record.dob), "years")} years</p> : 
          <p>{moment().diff(moment(record.dob), "months")} months</p>;
      },
      sorter: (a, b) => moment(b.dob).diff(moment(a.dob)),
      sortDirections: ["ascend","descend"]
    },
    {
      title: "Phone number",
      key: "phone",
      render: record => <p>{record.phone}</p>,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div className="p-2">
          <Input
            placeholder={"Search phone number"}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={confirm}
            className="w-48 my-2 block"
          />
          <Space>
            <Button
              type="primary"
              onClick={confirm}
              icon={<SearchOutlined />}
              size="small"
            >
              Search
            </Button>
            <Button 
              onClick={clearFilters} 
              size="small"
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined className={filtered ? "text-blue-500" : ""} />,
      onFilter: (value, record) =>
        record.phone
          ? record.phone.toString().toLowerCase().includes(value.toLowerCase())
          : ''
    },
    {
      title: "Government ID",
      key: "gov_id",
      render: record => <p>{record.gov_id.id_type}, {record.gov_id.id_no}</p>,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div className="p-2">
          <Input
            placeholder={"Search id number"}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={confirm}
            className="w-48 my-2 block"
          />
          <Space>
            <Button
              type="primary"
              onClick={confirm}
              icon={<SearchOutlined />}
              size="small"
            >
              Search
            </Button>
            <Button 
              onClick={clearFilters} 
              size="small"
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined className={filtered ? "text-blue-500" : ""} />,
      onFilter: (value, record) =>
        record.gov_id.id_no
          ? record.gov_id.id_no.toString().toLowerCase().includes(value.toLowerCase())
          : ''
    },
    {
      title: "Affiliations",
      key: "affiliations",
      render: record => record.affiliations.length ? record.affiliations.map(a => <p>{a.organisation.org_name}, {a.organisation.address.city}</p>) : <p>None</p>,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div className="p-2">
          <Input
            placeholder={"Search organisation"}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={confirm}
            className="w-48 my-2 block"
          />
          <Space>
            <Button
              type="primary"
              onClick={confirm}
              icon={<SearchOutlined />}
              size="small"
            >
              Search
            </Button>
            <Button 
              onClick={clearFilters} 
              size="small"
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined className={filtered ? "text-blue-500" : ""} />,
      onFilter: (value, record) =>
        record.affiliations.length ? 
          record.affiliations.find(a => a.organisation.org_name.toString().toLowerCase().includes(value.toLowerCase()))
          : ''
    },
    {
      title: "",
      key: "action",
      render: record => {
        return ( 
          <div className="flex space-x-2">
            <Tooltip title="Edit patron">
              <Button
                type="primary"
                className="bg-gray-600 hover:bg-gray-500 border-none"
                icon={<EditOutlined style={{verticalAlign: "middle"}}/>}
                shape="circle"
                onClick={() => props.history.push(`/edit-patron/${record.uuid}`)}
              />
            </Tooltip>
            <Button
              size="middle"
              type="primary"
              className="bg-teal-500 hover:bg-teal-400 border-none"
              shape="round"
              onClick={() => props.history.push(`/add-observation/${record.uuid}`)}
            >
              Add test observation
            </Button>
          </div>
        );
      }
    },
  ];

  if (loading) return <div className="center-div-on-screen"><Spin size="large"/></div>;

  if (error) console.log(error);

  return (
    <div className="p-8 mx-auto">
      <h2 className="text-xl text-center text-teal-700 mb-4">Patrons</h2>
      <div className="my-4 mx-auto overflow-x-auto text-gray-700">
        <Table 
          title={() => 
            <div className="flex flex-row justify-between">
              <p className="text-left font-semibold">Showing {count === null ? data.subject.length : count} Entries</p>
              <Button
                size="middle"
                type="primary"
                className="text-right bg-teal-500 hover:bg-teal-400 border-none"
                shape="round"
                onClick={() => props.history.push("/add-patron")}
              >
                Add patron
              </Button>
            </div>}
          dataSource={data.subject}
          columns={columns}
          locale={{emptyText:"No patrons yet"}}
          rowKey={(record) => record.uuid}
          expandable={{
            expandedRowRender: record => testResultsTable(record.labs),
            rowExpandable: record => record.labs.length
          }}
          onChange={(pagination, filters, sorter, extra) => setCount(extra.currentDataSource.length)}
        />
      </div>
      <BackTop />
    </div>
  );
}
