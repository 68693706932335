import * as React from "react";
import { Table, Spin, BackTop, Tooltip, Button  } from "antd";
import { EditOutlined } from "@ant-design/icons";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

export const GET_KITS = gql`
  query getKits {
    kit {
      uuid
      test_type
      kit_type
      kit_name
      manufacturer
    }
  }
`;

export default function Kits(props) {
  const { data, loading, error } = useQuery(GET_KITS, {
    fetchPolicy: "network-only"
  });
  const [count, setCount] = React.useState(null);

  const columns = [
    {
      title: "ID",
      key: "uuid",
      dataIndex: "uuid"
    },
    {
      title: "Name",
      key: "kit_name",
      dataIndex: "kit_name",
      sorter: (a, b) => a.kit_name.localeCompare(b.kit_name),
      sortDirections: ["ascend","descend"],
      defaultSortOrder: 'ascend'
    },
    {
      title: "Manufacturer",
      key: "manufacturer",
      render: record => <p>{record.manufacturer}</p>,
      sorter: (a, b) => a.manufacturer.localeCompare(b.manufacturer),
      sortDirections: ["ascend","descend"]
    },
    {
      title: "Test type",
      key: "test_type",
      filters: [
        { text: "COVID-19 Antibody", value: "COVIDAB" },
        { text: "COVID-19 Antigen", value: "COVIDAG" }
      ],
      onFilter: (value, record) => record.test_type.includes(value),
      filterMultiple: false,
      render: record => {
        return <p>{record.test_type === "COVIDAB" ? 
                    "COVID-19 Antibody" : 
                    "COVID-19 Antigen"}</p>;
      }
    },
    {
      title: "Kit type",
      key: "kit_type",
      filters: [
        { text: "PCR", value: "PCR" },
        { text: "ELISA", value: "ELISA" },
        { text: "CLIA", value: "CLIA" },
        { text: "LFA", value: "LFA" }
      ],
      onFilter: (value, record) => record.kit_type.includes(value),
      filterMultiple: false,
      render: record => <p>{record.kit_type}</p>
    },
    {
      title: "",
      key: "edit",
      render: record => {
        return ( 
          <Tooltip title="Edit kit">
            <Button
              type="primary"
              className="bg-gray-600 hover:bg-gray-500 border-none"
              icon={<EditOutlined style={{verticalAlign: "middle"}}/>}
              shape="circle"
              onClick={() => props.history.push(`/edit-kit/${record.uuid}`)}
            />
          </Tooltip>
        );
      }
    }
  ];

  if (loading) return <div className="center-div-on-screen"><Spin size="large"/></div>;

  if (error) console.log(error);

  return (
    <div className="p-8 mx-auto">
      <h2 className="text-xl text-center text-teal-700 mb-4">Kits</h2>
      <div className="my-4 mx-auto overflow-x-auto text-gray-700">
        <Table 
          title={() => <div className="flex flex-row justify-between">
            <p className="font-semibold">Showing {count === null ? data.kit.length : count} Entries</p>
            <Button
              size="middle"
              type="primary"
              className="bg-teal-500 hover:bg-teal-400 border-none"
              shape="round"
              onClick={() => props.history.push("/add-kit")}
            >
              Add kit
            </Button>
          </div>}
          dataSource={data.kit} 
          columns={columns} 
          locale={{emptyText:"No kits yet"}}
          rowKey={(record) => record.uuid}
          onChange={(pagination, filters, sorter, extra) => setCount(extra.currentDataSource.length)}/>
      </div>
      <BackTop />
    </div>
  );
}
