import * as React from "react";
import gql from "graphql-tag";
import { useMutation, useQuery, useLazyQuery } from "@apollo/react-hooks";
import { Input, Radio, Spin, BackTop, Button, Select, DatePicker } from "antd";
import ImageUpload from "../components/ImageUpload";
import DocUpload from "../components/DocUpload";
import { Form, Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { showNotification } from "../utils/index";
import moment from "moment";
import { AuthContext } from "../components/authProvider";
import { GET_LABS } from "./labs";
import { GET_DOCTORS } from "./doctors";

const { Option } = Select;

const { TextArea } = Input;

const GET_SUBJECT = gql`
  query getSubject($uuid: uuid!) {
    subject(where: {uuid: {_eq: $uuid}}) {
      name
      gender
      dob
    }
  }
`;

export const GET_KITS_BY_TYPE = gql`
  query getKits($test_type: test_type_enum!, $kit_type: kit_type_enum!) {
    kit(where: {_and: {test_type: {_eq: $test_type}, kit_type: {_eq: $kit_type}}}) {
      uuid
      kit_name
      manufacturer
    }
  }
`;

const INSERT_TEST_RESULT = gql`
  mutation addTest($obj: test_result_insert_input!) {
    insert_test_result_one(object: $obj) {
      uuid
      kit_no
      result
      remarks
      image
      lab_report
      test_date
      created_at
      kit {
        kit_name
        manufacturer
        test_type
        kit_type
      }
      lab_subject {
        lab {
          lab_name
          address
        }
      }
      doctor {
        name
      }
    }
  }
`;

export const testSchema = Yup.object().shape({
  lab: Yup.object().shape({
    key: Yup.string()
    .ensure()
    .required("Required"),
    value: Yup.string()
    .ensure()
    .required("Required"),
  }),
  // doctor: Yup.object().shape({
  //   key: Yup.string()
  //   .ensure()
  //   .required("Required"),
  //   value: Yup.string()
  //   .ensure()
  //   .required("Required"),
  // }),
  kit: Yup.object().shape({
    key: Yup.string()
    .ensure()
    .required("Required"),
    value: Yup.string()
    .ensure()
    .required("Required"),
  }),
  test_date: Yup.string()
    .ensure()
    .required("Required"),
  kit_no: Yup.string()
    .ensure()
    .required("Required"),
  result: Yup.string()
    .ensure()
    .required("Required"),
  // test_image: Yup.string()
  //   .ensure()
  //   .required("Required"),
  // lab_report: Yup.string()
  //   .ensure()
  //   .required("Required"),
  // barcode: Yup.string()
  //   .ensure()
  //   .required("Required")
});

export default function AddTest(props) {
  const authState = React.useContext(AuthContext);
  const { loading, data: subjectData, error } = useQuery(GET_SUBJECT, {
    variables: {
      uuid: props.match.params.id
    },
    fetchPolicy: "no-cache"
  });

  const { loading: loadingLabs, data: labData, error: errorLabs } = useQuery(GET_LABS);
  const { loading: loadingDocs, data: docData, error: errorDocs } = useQuery(GET_DOCTORS);

  const [ getKits, {loadingKits, data: kitData} ] = useLazyQuery(GET_KITS_BY_TYPE);

  const [insertTestResult] = useMutation(INSERT_TEST_RESULT);

  const onSubmit = async values => {
    console.log(values);

    await insertTestResult({
      variables: {
        obj: {
          doctor_id: values.doctor.key ? values.doctor.key : null,
          test_date: values.test_date,
          kit_id: values.kit.key,
          kit_no: values.kit_no.trim(),
          result: values.result,
          remarks: values.remarks.trim(),
          image: values.test_image,
          lab_report: {
            report: values.lab_report,
            barcode: values.barcode.trim()
          },
          lab_subject: {
            data: {
              lab_id: values.lab.key,
              subject_id: props.match.params.id
            },
            on_conflict: {
              constraint: "lab_subject_pkey",
              update_columns: ["lab_id", "subject_id"]
            }       
          }
        }
      }
    });

    props.history.push("/patrons");
    showNotification("success", "Success!", "Test observation submitted");
  };

  if (loading || loadingLabs || loadingDocs) return <div className="center-div-on-screen"><Spin size="large"/></div>;

  if (error) console.log(error);
  if (errorLabs) console.log(errorLabs);
  if (errorDocs) console.log(errorDocs);

  return (
    <div className="p-8 mx-auto">
      <h2 className="text-xl text-center text-teal-700 mb-4">
        Add test observation for{" "}
              {subjectData.subject[0].name} ({subjectData.subject[0].gender}, {moment().diff(moment(subjectData.subject[0].dob), "years") ? 
                                                                        `${moment().diff(moment(subjectData.subject[0].dob), "years")} years` : 
                                                                        `${moment().diff(moment(subjectData.subject[0].dob), "months")} months`})
      </h2>
      <Formik
        initialValues={{
          lab: {key: "", value: ""},
          doctor: {key: "", value: ""},
          test_date: "",
          test_type: "",
          kit_type: "",
          kit: {key: "", value: ""},
          kit_no: "",
          result: "",
          test_image: "",
          remarks: "",
          lab_report: "",
          barcode: ""
        }}
        validationSchema={testSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          setFieldTouched,
          setFieldError,
          isSubmitting
        }) => (
          <Form>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">Lab</label>
              <Select
                name="lab.value"
                size="large"
                placeholder="Select lab"
                className={errors.lab && touched.lab ? "w-full border-red-500" : "w-full border-gray-400"}
                onChange={(value, option) => setFieldValue("lab", option, true)}
                onBlur={handleBlur}
                value={values.lab.value}
                showSearch
                optionFilterProp="value"
                filterOption={(input, option) =>
                  option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                tokenSeparators={[',']}
                notFoundContent={"No labs yet"}
              >
                {labData.lab.map(lab => <Option key={lab.uuid} value={lab.lab_name}>{lab.lab_name}, {lab.address.city} - {lab.address.pincode}</Option>)}
              </Select>
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="lab.value"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">Referred by</label>
              <Select
                name="doctor.value"
                size="large"
                placeholder="Select doctor"
                className={errors.doctor && touched.doctor ? "w-full border-red-500" : "w-full border-gray-400"}
                onChange={(value, option) => setFieldValue("doctor", option, true)}
                onBlur={handleBlur}
                value={values.doctor.value}
                showSearch
                optionFilterProp="value"
                filterOption={(input, option) =>
                  option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                tokenSeparators={[',']}
                notFoundContent={"No doctors yet"}
              >
                {docData.doctor.map(doctor => <Option key={doctor.uuid} value={doctor.name}>{doctor.name}</Option>)}
              </Select>
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="doctor.value"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">Test date</label>
              <DatePicker
                name="test_date"
                format={"DD-MM-YYYY"}
                disabledDate={current => {
                  return !current || current.isAfter(moment().endOf("day")) || current.isBefore(moment("2020-01-01").startOf("day"));
                }}
                size="large"
                className={
                  errors.test_date && touched.test_date ? "w-full border-red-500" : "w-full border-gray-400"
                }
                onChange={
                  (date, dateString) => setFieldValue("test_date", moment(date).format("YYYY-MM-DD"))
                }
                onBlur={handleBlur}
                value={values.test_date !== "" ? moment(values.test_date) : ""}
              />
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="test_date"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">Test type</label>
              <Radio.Group
                className="flex"
                value={values.test_type}
                onChange={(e) => {
                  setFieldValue("kit", "", false);
                  setFieldValue("kit_type", "", false);
                  setFieldValue("test_type", e.target.value, true);
                }}
              >
                <Radio
                  className="block m-0 h-auto leading-8 text-gray-700"
                  value={"COVIDAB"}
                >
                  COVIDAB
                </Radio>
                <Radio
                  className="block m-0 h-auto leading-8 text-gray-700"
                  value={"COVIDAG"}
                >
                  COVIDAG
                </Radio>
              </Radio.Group>
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="test_type"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">Kit type</label>
              <Radio.Group
                className="flex"
                value={values.kit_type}
                onChange={(e) => {
                  setFieldValue("kit", "", false);
                  setFieldValue("kit_type", e.target.value, true);
                  getKits({
                    variables: {
                      test_type: values.test_type,
                      kit_type: e.target.value
                    }
                  });
                }}
              >
                { values.test_type !== "COVIDAB" ?
                <Radio
                  className="block m-0 h-auto leading-8 text-gray-700"
                  value={"PCR"}
                >
                  PCR
                </Radio> : null}
                <Radio
                  className="block m-0 h-auto leading-8 text-gray-700"
                  value={"ELISA"}
                >
                  ELISA
                </Radio>
                <Radio
                  className="block m-0 h-auto leading-8 text-gray-700"
                  value={"LFA"}
                >
                  LFA
                </Radio>
                <Radio
                  className="block m-0 h-auto leading-8 text-gray-700"
                  value={"CLIA"}
                >
                  CLIA
                </Radio>
              </Radio.Group>
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="kit_type"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">Kit</label>
              <Select
                name="kit.value"
                size="large"
                placeholder="Select kit"
                className={errors.kit && touched.kit ? "w-full border-red-500" : "w-full border-gray-400"}
                onChange={(value, option) => setFieldValue("kit", option, true)}
                onBlur={handleBlur}
                value={values.kit.value}
                showSearch
                optionFilterProp="value"
                filterOption={(input, option) =>
                  option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                tokenSeparators={[',']}
                loading={loadingKits}
                notFoundContent={values.test_type && values.kit_type ? `No ${values.test_type} ${values.kit_type} kits yet` : "Select test type and kit type first"}
              >
                {kitData && kitData.kit.map(kit => <Option key={kit.uuid} value={kit.kit_name}>{kit.kit_name}, {kit.manufacturer}</Option>)}
              </Select>
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="kit.value"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">Kit/Sample ID</label>
              <Input
                size="large"
                type="text"
                name="kit_no"
                className={errors.kit_no && touched.kit_no ? "border-red-500" : "border-gray-400"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.kit_no}
                
              />
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="kit_no"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">Result</label>
              <Radio.Group
                className="flex"
                value={values.result}
                onChange={e => {
                  console.log(e);
                  setFieldValue("result", e.target.value, true);
                }}
              >
                <Radio
                  className="block m-0 h-auto leading-8 text-gray-700"
                  value={true}
                >
                  Positive
                </Radio>
                <Radio
                  className="block m-0 h-auto leading-8 text-gray-700"
                  value={false}
                >
                  Negative
                </Radio>
              </Radio.Group>
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="result"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">Kit image</label>
              <ImageUpload
                accept="image/*"
                multiple={false}
                name={!!values.test_image ? "Image" : "kit_image"}
                error={touched.test_image ? errors.test_image : null}
                className={errors.test_image && touched.test_image ? "border-red-500" : "border-gray-400"}
                value={values.test_image}
                headers={{authorization: `Bearer ${authState.token}`}}
                onSuccess={data => {
                  setFieldValue("test_image", data[0], true);
                  setFieldTouched("test_image", true, true);
                }}
                onError={error => {
                  setFieldTouched("test_image", true, false);
                  setFieldError("test_image", error);
                }}
              />
              {!!values.test_image ? null :
              <p className="text-gray-800 text-xs italic">Upload photo with legible kit number and result (max size less than 2 MB)</p>}
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="test_image"
                  component="div"
                  className="text-red-500 text-center mb-2"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">Remarks</label>
              <TextArea
                size="large"
                type="text"
                name="remarks"
                className={
                  errors.remarks && touched.remarks
                    ? "border-red-500" : "border-gray-400"
                }
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.remarks}
                
                autoSize={{ minRows: 2, maxRows: 10 }}
              />
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="remarks"
                  component="div"
                  className="error-message"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">Lab report</label>
              <DocUpload
                accept=".pdf"
                multiple={false}
                name={!!values.lab_report ? "Report" : "lab_report"}
                error={touched.lab_report ? errors.lab_report : null}
                className={errors.lab_report && touched.lab_report ? "border-red-500" : "border-gray-400"}
                value={values.lab_report}
                headers={{authorization: `Bearer ${authState.token}`}}
                onSuccess={data => {
                  setFieldValue("lab_report", data[0], true);
                  setFieldTouched("lab_report", true, true);
                }}
                onError={error => {
                  console.log(error);
                  setFieldTouched("lab_report", true, false);
                  setFieldError("lab_report", error);
                }}
              />
              {!!values.lab_report ? null :
                <p className="text-gray-800 text-xs italic">Upload lab report with legible barcode and result (max size less than 2 MB)</p>}
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="lab_report"
                  component="div"
                  className="text-red-500 text-center mb-2"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">Lab report barcode</label>
              <Input
                size="large"
                type="text"
                name="barcode"
                className={errors.barcode && touched.barcode ? "border-red-500" : "border-gray-400"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.barcode}
                
              />
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="barcode"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <Button
              size="middle"
              type="primary"
              className="bg-teal-500 hover:bg-teal-400 border-none"
              shape="round"
              block={true}
              htmlType="submit"
              loading={isSubmitting}
            >
              Submit observation
            </Button>
          </Form>
        )}
      </Formik>
      <BackTop />
    </div>
  );
}
