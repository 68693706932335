import React from "react";
import "antd/dist/antd.css";
import { Router, Route, Switch } from "react-router-dom";
import history from "./utils/history";

import { ApolloProvider } from "@apollo/react-hooks";
import { ApolloClient, HttpLink, InMemoryCache} from "apollo-boost";
import { onError } from "apollo-link-error";
import { setContext } from "apollo-link-context";
// import { InMemoryCache } from "apollo-cache-inmemory";

import Login from "./pages/login";
import CompleteProfile from "./pages/completeProfile";
import Profile from "./pages/profile";
import EditProfile from "./pages/editProfile";
import AddOrg from "./pages/addOrg";
import EditOrg from "./pages/editOrg";
import Orgs from "./pages/orgs";
import AddLab from "./pages/addLab";
import EditLab from "./pages/editLab";
import Labs from "./pages/labs";
import AddSubject from "./pages/addSubject";
import EditSubject from "./pages/editSubject";
import Subjects from "./pages/subjects";
import AddDoctor from "./pages/addDoctor";
import EditDoctor from "./pages/editDoctor";
import Doctors from "./pages/doctors";
import AddTest from "./pages/addTest";
import EditTest from "./pages/editTest";
import AddKit from "./pages/addKit";
import EditKit from "./pages/editKit";
import Kits from "./pages/kits";
import Tests from "./pages/tests";
import ForgotPassword from "./pages/forgotPassword";
import NotFound from "./pages/pageNotFound";

import PrivateRoute from "./components/privateRoute";
import { Header } from "./components/header";
import { TabContainer } from "./components/tabContainer";

import { AuthContext } from "./components/authProvider";

import "./styles/index.css";


export const DB_DOMAIN = "https://dbdroplet.indiahealth.com";

function App() {
  const authContext = React.useContext(AuthContext);
  const httpLink = new HttpLink({
    uri: `${DB_DOMAIN}/v1/graphql`,
  });
  
  const authLink = setContext((_, { headers }) => {
    const token = authContext.token;
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : ""
      }
    };
  });
  
  const errorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      console.log("Error");
      console.log("graphQLError", graphQLErrors);
      console.log("networkError", networkError);
      if (graphQLErrors)
        graphQLErrors.map(async ({ message, extensions }) => {
          switch (extensions.code) {
            case "data-exception":
              break;
            case "invalid-headers":
              console.log("invalid-headers");
              window.location.href = "/";
              break;
            case "validation-failed":
              // props.history.push("/something-went-wrong");
              break;
            case "invalid-jwt":
              console.log("invalid-jwt");
              // Refresh token
              await authContext.fetchIdToken(true);
              window.location.href = "/";
              break;
            default:
              // default case
              console.log(extensions.code);
          }
        });
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
        // props.history.push("/network-error");
      }
    }
  );
  
  const client = new ApolloClient({
    link: errorLink.concat(authLink.concat(httpLink)),
    cache: new InMemoryCache()
  });

  return (
    <ApolloProvider client={client}>
      <Router history={history}>
        <div style={{ minHeight: "100vh" }} className="bg-gray-100">
          <Header />
          <div
            style={{
              maxWidth: "1600px",
              minHeight: "calc(100vh - 70px)"
            }}
            className="p-8 flex m-auto"
          >
            <div className="w-full lg:w-11/12 text-md shadow-lg overflow-hidden bg-white mt-0 mb-auto mx-auto rounded-lg">
            <TabContainer client={client} history={history} />
              <Switch>
                <Route path="/" exact component={Login} />
                <Route path="/forgot-password" exact component={ForgotPassword} />
                <PrivateRoute
                  path="/complete-profile"
                  exact
                  component={CompleteProfile}
                  history={history}
                />
                <PrivateRoute
                  path="/profile"
                  exact
                  component={Profile}
                  history={history}
                />
                <PrivateRoute
                  path="/edit-profile"
                  exact
                  component={EditProfile}
                  history={history}
                />
                <PrivateRoute
                  path="/add-organisation"
                  exact
                  component={AddOrg}
                  history={history}
                />
                <PrivateRoute
                  path="/edit-organisation/:id"
                  exact
                  component={EditOrg}
                  history={history}
                />
                <PrivateRoute
                  path="/organisations"
                  exact
                  component={Orgs}
                  history={history}
                />
                <PrivateRoute
                  path="/add-lab"
                  exact
                  component={AddLab}
                  history={history}
                />
                <PrivateRoute
                  path="/edit-lab/:id"
                  exact
                  component={EditLab}
                  history={history}
                />
                <PrivateRoute
                  path="/labs"
                  exact
                  component={Labs}
                  history={history}
                />
                <PrivateRoute
                  path="/add-patron"
                  exact
                  component={AddSubject}
                  history={history}
                />
                <PrivateRoute
                  path="/edit-patron/:id"
                  exact
                  component={EditSubject}
                  history={history}
                />
                <PrivateRoute
                  path="/patrons"
                  exact
                  component={Subjects}
                  history={history}
                />
                <PrivateRoute
                  path="/add-doctor"
                  exact
                  component={AddDoctor}
                  history={history}
                />
                <PrivateRoute
                  path="/edit-doctor/:id"
                  exact
                  component={EditDoctor}
                  history={history}
                />
                <PrivateRoute
                  path="/doctors"
                  exact
                  component={Doctors}
                  history={history}
                />
                <PrivateRoute
                  path="/add-observation/:id"
                  exact
                  component={AddTest}
                  history={history}
                />
                <PrivateRoute
                  path="/edit-observation/:id"
                  exact
                  component={EditTest}
                  history={history}
                />
                <PrivateRoute
                  path="/add-kit"
                  exact
                  component={AddKit}
                  history={history}
                />
                <PrivateRoute
                  path="/edit-kit/:id"
                  exact
                  component={EditKit}
                  history={history}
                />
                <PrivateRoute
                  path="/kits"
                  exact
                  component={Kits}
                  history={history}
                />
                <PrivateRoute
                  path="/tests"
                  exact
                  component={Tests}
                  history={history}
                />
                <Route path="*" component={NotFound} />
              </Switch>
            </div>
          </div>

          <div
            className="w-full p-4 text-center text-white"
            style={{ height: "70px", backgroundColor: "#323732" }}
          > 
            <p>
              {" "}
              © 2020. A Capital Health Systems Initiative. Platform powered by <strong>Qubento</strong>.
            </p>
          </div>
        </div>
      </Router>
    </ApolloProvider>
  );
}

export default App;
