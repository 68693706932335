import * as React from "react";

export default function NotFound(props) {
    return (
        <div className="p-8 mx-auto">
          <h2 className="text-xl text-center text-teal-700 mb-4">404 page not found</h2>
          <p>We are sorry but the page you are looking for does not exist</p>
        </div>
    );
}
