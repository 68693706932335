import * as React from "react";
import { Table, Spin, BackTop, Tooltip, Button, Space, Input } from "antd";
import { EyeOutlined, FileTextOutlined, SearchOutlined, EditOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import moment from "moment";
import { getDownloadSignedUrl } from "../utils/s3_helper";
import { AuthContext } from "../components/authProvider";


export const GET_TESTS = gql`
  query getTests {
    test_result {
      uuid
      test_date
      kit_no
      kit {
        test_type
        kit_type
        kit_name
        manufacturer
      }
      result
      remarks
      image
      lab_report
      status      
      lab_subject {
        lab {
          lab_name
          address
        }
        subject {
          uuid
          created_at
          name
          gender
          dob
          gov_id
          phone
          affiliations {
            organisation {
              org_name
              address
            }
          }
        }
      }
      doctor {
        name
      }
    }
  }
`;

export default function Tests(props) {
  const authState = React.useContext(AuthContext);
  const { data, loading, error } = useQuery(GET_TESTS, {fetchPolicy: "network-only"});
  const [count, setCount] = React.useState(null);

  const downloadUrl = async (event, publicUrl) => {
    event.preventDefault();
    const url = await getDownloadSignedUrl(publicUrl, {headers: {authorization: `Bearer ${authState.token}`}});
    let downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.target = "_blank";
    downloadLink.download = "doc";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const columns = [
    {
      title: "Test date",
      key: "test_date",
      render: record => {
        return <p>{moment(record.test_date).format("DD-MM-YYYY")}</p>;
      },
      sorter: (a, b) => moment(a.test_date).diff(moment(b.test_date)),
      sortDirections: ["ascend","descend"],
      defaultSortOrder: 'descend'
    },
    {
      title: "Lab",
      key: "lab",
      render: record => <p className="capitalize">{record.lab_subject.lab.lab_name}, {record.lab_subject.lab.address.city}</p>,
      sorter: (a, b) => a.lab_subject.lab.lab_name.localeCompare(b.lab_subject.lab.lab_name),
      sortDirections: ["ascend","descend"],
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div className="p-2">
          <Input
            placeholder={"Search lab name"}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={confirm}
            className="w-48 my-2 block"
          />
          <Space>
            <Button
              type="primary"
              onClick={confirm}
              icon={<SearchOutlined />}
              size="small"
            >
              Search
            </Button>
            <Button 
              onClick={clearFilters} 
              size="small"
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined className={filtered ? "text-blue-500" : ""} />,
      onFilter: (value, record) =>
        record.lab_subject.lab.lab_name
          ? record.lab_subject.lab.lab_name.toString().toLowerCase().includes(value.toLowerCase())
          : ''
    },
    {
      title: "Referred by",
      key: "doctor",
      render: record => record.doctor ? <p className="capitalize">Dr. {record.doctor.name}</p> : <p>Not available</p>,
      sorter: (a, b) => a.doctor.name.localeCompare(b.doctor.name),
      sortDirections: ["ascend","descend"],
    },
    {
      title: "Test type",
      key: "test_type",
      filters: [
        { text: "COVID-19 Antibody", value: "COVIDAB" },
        { text: "COVID-19 Antigen", value: "COVIDAG" }
      ],
      onFilter: (value, record) => record.kit.test_type.includes(value),
      filterMultiple: false,
      render: record => {
        return <p>{record.kit.test_type === "COVIDAB" ? 
                    "COVID-19 Antibody" : 
                    "COVID-19 Antigen"}</p>;
      }
    },
    {
      title: "Kit",
      key: "kit",
      render: record => {
        return(
          <div>
            <p>Kit name: {record.kit.kit_name}</p>
            <p>Manufacturer: {record.kit.manufacturer}</p>
            <p>Kit type: {record.kit.kit_type}</p>
            <p>Kit/Sample ID: {record.kit_no}</p>
          </div>
        );
      },
      sorter: (a, b) => a.kit.kit_name.localeCompare(b.kit.kit_name),
      sortDirections: ["ascend","descend"]
    },
    {
      title: "Result",
      key: "result",
      filters: [
        { text: "Positive", value: true },
        { text: "Negative", value: false }
      ],
      onFilter: (value, record) => record.result === value,
      render: record => {
        return (
        <div>
          <p>{record.result ? "Positive" : "Negative"}</p>
          {record.lab_report.report &&
          <Tooltip title="Open document">
            <Button
              icon={<FileTextOutlined style={{verticalAlign: "middle"}}/>}
              shape="circle"
              type="primary"
              className="bg-gray-600 hover:bg-gray-500 border-none"
              onClick={(event) => downloadUrl(event, record.lab_report.report)}
            />
          </Tooltip>}
          {record.image &&
          <Tooltip title="View">
            <Button
              icon={<EyeOutlined style={{verticalAlign: "middle"}}/>}
              shape="circle"
              type="primary"
              className="bg-gray-600 hover:bg-gray-500 border-none"
              onClick={(event) => downloadUrl(event, record.image)}
            />
          </Tooltip>}
        </div>
        );
      }
    },
    {
      title: "Validation status",
      key: "status",
      render: record => <p>{record.status}</p>,
      sortDirections: ["ascend","descend"],
      filters: [
        { text: "Pending", value: "New" },
        { text: "Approved", value: "Approved" },
        { text: "Rejected", value: "Rejected" }
      ],
      onFilter: (value, record) => record.status.includes(value),
      filterMultiple: false
    },
    {
      title: "Patron details",
      children: [
        {
          title: "ID and name",
          key: "subject_id",
          render: record => <div><p>{record.lab_subject.subject.uuid}</p><p>{record.lab_subject.subject.name}</p></div>,
          sorter: (a, b) => a.lab_subject.subject.name.localeCompare(b.lab_subject.subject.name),
          sortDirections: ["ascend","descend"],
          filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div className="p-2">
              <Input
                placeholder={"Search patron ID"}
                value={selectedKeys[0]}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={confirm}
                className="w-48 my-2 block"
              />
              <Space>
                <Button
                  type="primary"
                  onClick={confirm}
                  icon={<SearchOutlined />}
                  size="small"
                >
                  Search
                </Button>
                <Button 
                  onClick={clearFilters} 
                  size="small"
                >
                  Reset
                </Button>
              </Space>
            </div>
          ),
          filterIcon: filtered => <SearchOutlined className={filtered ? "text-blue-500" : ""} />,
          onFilter: (value, record) =>
            record.lab_subject.subject.uuid
              ? record.lab_subject.subject.uuid.toString().toLowerCase().includes(value.toLowerCase())
              : ''
        },
        {
          title: "Phone number",
          key: "phone",
          render: record => <p>{record.lab_subject.subject.phone}</p>,
          filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div className="p-2">
              <Input
                placeholder={"Search phone number"}
                value={selectedKeys[0]}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={confirm}
                className="w-48 my-2 block"
              />
              <Space>
                <Button
                  type="primary"
                  onClick={confirm}
                  icon={<SearchOutlined />}
                  size="small"
                >
                  Search
                </Button>
                <Button 
                  onClick={clearFilters} 
                  size="small"
                >
                  Reset
                </Button>
              </Space>
            </div>
          ),
          filterIcon: filtered => <SearchOutlined className={filtered ? "text-blue-500" : ""} />,
          onFilter: (value, record) =>
            record.lab_subject.subject.phone
              ? record.lab_subject.subject.phone.toString().toLowerCase().includes(value.toLowerCase())
              : ''
        },
        {
          title: "Government ID",
          key: "gov_id",
          render: record => <p>{record.lab_subject.subject.gov_id.id_type}, {record.lab_subject.subject.gov_id.id_no}</p>,
          filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div className="p-2">
              <Input
                placeholder={"Search id number"}
                value={selectedKeys[0]}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={confirm}
                className="w-48 my-2 block"
              />
              <Space>
                <Button
                  type="primary"
                  onClick={confirm}
                  icon={<SearchOutlined />}
                  size="small"
                >
                  Search
                </Button>
                <Button 
                  onClick={clearFilters} 
                  size="small"
                >
                  Reset
                </Button>
              </Space>
            </div>
          ),
          filterIcon: filtered => <SearchOutlined className={filtered ? "text-blue-500" : ""} />,
          onFilter: (value, record) =>
            record.lab_subject.subject.gov_id.id_no
              ? record.lab_subject.subject.gov_id.id_no.toString().toLowerCase().includes(value.toLowerCase())
              : ''
        },
        {
          title: "Affiliations",
          key: "affiliations",
          render: record => record.lab_subject.subject.affiliations.length ? record.lab_subject.subject.affiliations.map(a => <p>{a.organisation.org_name}, {a.organisation.address.city}</p>) : <p>None</p>,
          filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div className="p-2">
              <Input
                placeholder={"Search organisation"}
                value={selectedKeys[0]}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={confirm}
                className="w-48 my-2 block"
              />
              <Space>
                <Button
                  type="primary"
                  onClick={confirm}
                  icon={<SearchOutlined />}
                  size="small"
                >
                  Search
                </Button>
                <Button 
                  onClick={clearFilters} 
                  size="small"
                >
                  Reset
                </Button>
              </Space>
            </div>
          ),
          filterIcon: filtered => <SearchOutlined className={filtered ? "text-blue-500" : ""} />,
          onFilter: (value, record) =>
            record.lab_subject.subject.affiliations.length ? 
              record.lab_subject.subject.affiliations.find(a => a.organisation.org_name.toString().toLowerCase().includes(value.toLowerCase()))
              : ''
        }
      ]
    },
    {
      title: "",
      key: "edit",
      render: record => {
        return (
          record.status === "New" && 
          <Tooltip title="Edit observation">
            <Button
              type="primary"
              className="bg-gray-600 hover:bg-gray-500 border-none"
              icon={<EditOutlined style={{verticalAlign: "middle"}}/>}
              shape="circle"
              onClick={() => props.history.push(`/edit-observation/${record.uuid}`)}
            />
          </Tooltip>
        );
      }
    }
  ];

  if (loading) return <div className="center-div-on-screen"><Spin size="large"/></div>;

  if (error) console.log(error);

  return (
    <div className="p-8 mx-auto">
      <h2 className="text-xl text-center text-teal-700 mb-4">Tests</h2>
      <div className="my-4 mx-auto overflow-x-auto text-gray-700">
        <Table
          title={() => <p className="font-semibold">Showing {count === null ? data.test_result.length : count} Entries</p>}
          dataSource={data.test_result}
          columns={columns}
          rowKey={(record) => record.uuid}
          locale={{emptyText:"No tests yet"}}
          bordered
          expandable={{
            expandedRowRender: record => <p> Remarks: {record.remarks} </p>,
            rowExpandable: record => record.remarks,
          }}
          onChange={(pagination, filters, sorter, extra) => setCount(extra.currentDataSource.length)}
        />
      </div>
      <BackTop />
    </div>
  );
}
